import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import { deepMerge } from 'lib/utils';
import { externalTooltipHandlerDoughnutChart } from 'lib/tooltips/externalTooltipHandlerDoughnutChart';
Chart.register(ArcElement);

export const DoughnutChart = ({ options = {}, data, ...rest }) => {
  const defaultOptions = {
    cutout: 32,
    responsive: true,
    parsing: {
      key: 'nested.value',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandlerDoughnutChart,
      },
    },
  };

  const finalOptions = deepMerge(defaultOptions, options);

  const pluginsOptions = [
    {
      id: 'custom-empty-state-doughnut-chart',
      afterDatasetsDraw: (chart, args, plugins) => {
        const {
          ctx,
          data,
          chartArea: { top, left, width, height },
        } = chart;

        ctx.save();

        if (data.datasets.length === 0) {
          ctx.fillStyle = '#FFF';
          ctx.fillRect(left, top, width, height);

          ctx.textAlign = 'center';
          ctx.font = '400 1rem Poppins, Inter, sans-serif';
          ctx.fillStyle = '#2222228F';

          ctx.fillText(
            'Dados insuficientes para gerar o relatório',
            left + width / 2,
            top + height / 2
          );
        }
      },
    },
  ];

  return (
    <Doughnut
      type='doughnut'
      options={finalOptions}
      data={data}
      {...rest}
      plugins={pluginsOptions}
    />
  );
};
