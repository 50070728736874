import { formatToBrazilianCurrency } from "lib/helpers/format";
import { useMemo } from "react";

const usePaymentMetricData = (relatorios) => {
  return useMemo(() => {
    return [
      {
        title: "Conversão de boleto",
        value: `${formatToBrazilianCurrency(relatorios?.boleto_valor)}`,
        qauntity: relatorios?.boleto_quantidade,
        percent: relatorios?.boleto_percentual,
      },
      {
        title: "Reembolso",
        value: `${formatToBrazilianCurrency(relatorios?.reembolso_valor)}`,
        qauntity: relatorios?.reembolso_quantidade,
        percent: relatorios?.reembolso_percentual,
      },
      {
        title: "Chargeback",
        value: `${formatToBrazilianCurrency(relatorios?.chargeback_valor)}`,
        qauntity: relatorios?.chargeback_quantidade,
        percent: relatorios?.chargeback_percentual,
      },
    ];
  }, [relatorios]);
};

export default usePaymentMetricData;
