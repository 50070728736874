import { Line } from "react-chartjs-2";
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { deepMerge } from "lib/utils";
import { externalTooltipHandlerLineChart } from "lib/tooltips/externalTooltipHandlerLineChart";
Chart.register(LineElement, CategoryScale, LinearScale, PointElement);

export const LineChart = ({ options, data, ...rest }) => {
  const defaultOptions = {
    cutout: 40,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandlerLineChart,
      },
    },
    parsing: {
      xAxisKey: "id",
      yAxisKey: "nested.value",
    },
  };

  const pluginsOptions = [
    {
      id: "custom-empty-state-line-chart",
      afterDatasetsDraw: (chart, args, plugins) => {
        const {
          ctx,
          data,
          chartArea: { top, left, width, height },
        } = chart;

        ctx.save();

        if (data.datasets.length === 0) {
          ctx.fillStyle = "#FFF";
          ctx.fillRect(left, top, width, height);

          ctx.textAlign = "center";
          ctx.font = "400 1rem Poppins, Inter, sans-serif";
          ctx.fillStyle = "#2222228F";

          ctx.fillText(
            "Dados insuficientes para gerar o relatório",
            left + width / 2,
            top + height / 2
          );
        }
      },
    },
  ];

  const finalOptions = deepMerge(defaultOptions, options);

  return (
    <Line
      type="line"
      options={finalOptions}
      data={data}
      {...rest}
      plugins={pluginsOptions}
    />
  );
};
