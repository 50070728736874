import styled from "styled-components";
import { Card as CardMUI } from "@mui/material";
import pallete from "TGComponents/theme/pallete";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.neutral[60]};
  box-shadow: none;
  background-color: rgba(250, 250, 250, 1);
  height: 100%;
`;
