import dashboardActionTypes from "../actionTypes/dashboard";

const initialState = {
  results: null,
  resultsCards: null,
  loading: false,
  periodo: "semestre",
  data_inicial: null,
  data_final: null,
  tipo: null,
  uuid_produto: null,
  loadingInitial: true,
  loadingType: null,
  loadingCards: false,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case dashboardActionTypes.SET_RESULTS: {
      const { results } = action.payload;
      return {
        ...state,
        results,
      };
    }

    case dashboardActionTypes.SET_RESULTS_CARDS: {
      const { results } = action.payload;
      return {
        ...state,
        resultsCards: results,
      };
    }

    case dashboardActionTypes.SET_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        loading: flag,
      };
    }

    case dashboardActionTypes.SET_PARAM: {
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value,
      };
    }

    case dashboardActionTypes.SET_LOADING_INITIAL: {
      const { flag } = action.payload;

      return {
        ...state,
        loadingInitial: flag,
      };
    }

    case dashboardActionTypes.SET_LOADING_CARDS: {
      const { flag } = action.payload;

      return {
        ...state,
        loadingCards: flag,
      };
    }

    case dashboardActionTypes.SET_LOADING_TYPE: {
      const { type } = action.payload;

      return {
        ...state,
        loadingType: type,
      };
    }

    case dashboardActionTypes.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};

export default dashboard;
