import { Link } from "react-router-dom";
import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { radius } from "TGComponents/theme/radius";

export const Title = styled.h1`
  color: ${pallete.base.black};
  font-size: 1.5rem;
  line-height: 1.334;
  font-weight: 600;
  color: initial;
  margin: 0;
`;

export const LinkPage = styled(Link)`
  text-decoration: none !important;
  color: #00a7f0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1.8rem;
  display: inline-block;

  svg {
    margin-left: 1rem;
  }
`;

export const ScrollerHorizontal = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1.25rem;
  /* scrollbar-color: #ebeef0 #f7f9fa; */

  &::-webkit-scrollbar-track {
    background: #f7f9fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebeef0;
  }
`;

export const CardProductSkeleton = styled.div`
  padding: 1.25rem;
  display: flex;
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  flex-basis: 300px;
  flex-shrink: 0;
`;
