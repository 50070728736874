import moment from "moment";

function getDifferenceInDays(startDate, endDate) {
  const targetDate = moment(startDate);

  const currentDate = moment(endDate);

  const differenceInDays = targetDate.diff(currentDate, "days");

  return differenceInDays;
}

function formatDate(date) {
  return moment(date).format("DD/MM/YYYY");
}

function formatDateToMonthAndDay(date) {
  return moment(date).format("DD MMM");
}

function formatDateToMonth(date) {
  const dateValue = moment(date).format("MMM");
  const formatDate = dateValue.charAt(0).toUpperCase() + dateValue.slice(1);
  return formatDate;
}

function generateMonthlyDates(period, months) {
  const today = moment();
  const dates = [];

  for (let i = 0; i < months; i++) {
    let monthEnd = today.clone().subtract(i, "months").endOf("month");

    if (i === 0) {
      monthEnd = moment.min(monthEnd, today).toDate();
    } else {
      monthEnd = monthEnd.toDate();
    }

    dates.push({
      data_pg: moment(monthEnd).format("YYYY-MM-DD"),
      venda_acumulada: "0",
      quantidade: "0",
    });
  }

  return dates;
}
function aggregateByMonth(array, months) {
  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  // Função para agregar os dados por mês
  const aggregatedData = array.reduce((acc, item) => {
    const date = moment(item.data_pg, "YYYY-MM-DD");
    const monthEnd = date.endOf("month").toDate();

    // Encontra ou cria o item do mês no acumulador
    const existingItem = acc.find((d) => d.data_pg === formatDate(monthEnd));
    if (existingItem) {
      existingItem.venda_acumulada = (
        parseFloat(
          existingItem.venda_acumulada.replace(".", "").replace(",", ".")
        ) + parseFloat(item.venda_acumulada.replace(".", "").replace(",", "."))
      )
        .toFixed(2)
        .replace(".", ",");
      existingItem.quantidade = (
        parseInt(existingItem.quantidade) + parseInt(item.quantidade)
      ).toString();
    } else {
      acc.push({
        data_pg: formatDate(monthEnd),
        venda_acumulada: item.venda_acumulada,
        quantidade: item.quantidade,
      });
    }
    return acc;
  }, []);

  const monthlyDates = generateMonthlyDates(null, months);

  monthlyDates.forEach((dateObj) => {
    const index = aggregatedData.findIndex(
      (item) => item.data_pg === dateObj.data_pg
    );
    if (index === -1) {
      aggregatedData.push(dateObj);
    }
  });

  const sortedArray = aggregatedData.sort(
    (a, b) => new Date(a.data_pg) - new Date(b.data_pg)
  );

  return sortedArray;
}

function addAfterDays(array, period) {
  const periodMap = {
    lastSevenDays: 7,
    lastMonth: 30,
    lastSixMonths: 6,
    lastTwelveMonths: 12,
  };

  const days = periodMap[period];
  if (period === "lastSixMonths" || period === "lastTwelveMonths") {
    return aggregateByMonth(array, periodMap[period]);
  }

  if (days) {
    const formatDate = (date) => moment(date).format("YYYY-MM-DD");
    const today = moment();
    const dates = [];

    for (let i = 0; i < days; i++) {
      const date = today.clone().subtract(i, "days").toDate();
      dates.push({
        data_pg: formatDate(date),
        venda_acumulada: "0",
        quantidade: "0",
      });
    }

    dates.forEach((dateObj) => {
      const index = array.findIndex((item) => item.data_pg === dateObj.data_pg);
      if (index === -1) {
        array.push(dateObj);
      }
    });

    const sortedArray = array.sort(
      (a, b) => new Date(a.data_pg) - new Date(b.data_pg)
    );
    return sortedArray;
  }

  return array;
}

function isDateToday(dateStr, dateFormat = "DD/MM/YYYY") {
  const specificDate = moment(dateStr, dateFormat, "pt");
  const today = moment();

  return specificDate.isSame(today, "day");
}
export {
  getDifferenceInDays,
  formatDate,
  formatDateToMonthAndDay,
  addAfterDays,
  formatDateToMonth,
  isDateToday,
};
