import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useNavigate } from 'react-router-dom'
import TGIcon from 'TGComponents/global/TGIcon'
import { Box } from '@mui/material'

const TGDropdown = ({
  options,
  onSelect,
  customIcon,
  customMenuIcon,
  square = false,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (option) => {
    const { to, tabIndex, ...rest } = option
    if (to) {
      navigate(to, { state: { tabIndex, ...rest } }) // Passando tabIndex e outros dados via state
    } else {
      onSelect(option)
    }
    handleClose()
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <IconButton
          aria-label='more'
          aria-controls={open ? 'tg-dropdown-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          disabled={disabled}
          sx={
            square
              ? {
                bgcolor: '#fafafa',
                borderRadius: '8px',
                border: '1px solid #EEEEEE',
                width: '33px',
                height: '33px',
              }
              : {}
          }
        >
          {customIcon ? customIcon : <MoreVertIcon />}
        </IconButton>
        <Menu
          id='tg-dropdown-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotsPaperProps={{
            style: {
              maxHeight: options.length * 48,
              width: '20ch',
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.label}
              onClick={(event) => handleMenuItemClick(option, event)}
              style={{
                minHeight: '40px',
                alignItems: 'center',
                fontSize: '14px',
                color: 'neutral.30',
              }}
            >
              {customMenuIcon && (
                <Box mr='16px'>
                  <TGIcon src={customMenuIcon} height='16px' width='13px' />
                </Box>
              )}

              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  )
}

export default TGDropdown
