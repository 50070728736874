import React from "react";
import {
  MetricCard,
  Progress,
  ProgressBar,
  Content,
  Title,
  Value,
  Percent,
  Container,
} from "./styles";
import { Skeleton } from "@mui/material";

const PaymentMetricsSkeleton = ({ data }) => {
  return (
    <Container>
      {data.map((metric) => (
        <MetricCard key={metric.title}>
          <Progress percent={metric.percent}>
            <ProgressBar variant="determinate" value={metric.percent} />
          </Progress>

          <Content>
            <Title>{metric.title}</Title>
            <Value>
              <Skeleton variant="rounded" width={136} height={14} />
            </Value>
          </Content>
          <Percent>{metric.percent}%</Percent>
        </MetricCard>
      ))}
    </Container>
  );
};

export default PaymentMetricsSkeleton;
