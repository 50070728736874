import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";

export const Title = styled("p")`
  font-size: 1.125rem;
  font-weight: bold;
  color: ${pallete.base.black};
  margin-top: 24px;
  margin-bottom: 8px;
  text-align: center;
`;

export const Subtitle = styled("p")`
  font-size: 0.875rem;
  color: ${pallete.base.black};
  color: ${pallete.base.blackAlpha};
  text-align: center;
`;
