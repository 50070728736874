import { Box, CardContent } from "@mui/material";
import React, { useMemo } from "react";
import {
  Card,
  BoxGraph,
  CardBody,
  Title,
  Legend,
  LegendItem,
  Labels,
  LabelsItem,
} from "./styles";
import { BarChart } from "TGComponents/global/charts/BarChart";

const CardBestSellers = ({ legend, labels = [], options, data }) => {
  return (
    <>
      <Card>
        <CardContent>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title>Mais vendidos</Title>
            {labels?.length > 0 && (
              <Labels>
                {labels.map((label) => (
                  <LabelsItem key={label} type={label}>
                    {label}
                  </LabelsItem>
                ))}
              </Labels>
            )}
          </Box>
          <CardBody>
            <Legend>
              {legend?.length > 0 &&
                legend.map((leg) => <LegendItem key={leg}>{leg}</LegendItem>)}
            </Legend>
            <BoxGraph>
              <BarChart {...{ options, data }} />
            </BoxGraph>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardBestSellers;
