import React, { useState } from "react";
import { Title, ScrollerWrapper } from "./styles";
import { Box, Grid } from "@mui/material";
import {
  CardBestSellers,
  CardEvolutionSales,
  CardPaymentsForms,
  EmptyStateAllSales,
  Notfound,
  PaymentMetrics,
} from "../..";
import { CardToggleInfo } from "TGComponents/global/cards";
import TabAllSalesSkeleton from "./TabAllSalesSkeleton";

import {
  useCardsPerformance,
  useDataEvolution,
  useGraphBestSellers,
  useGraphData,
  usePaymentMetricData,
} from "./hooks";

const TabAllSales = ({
  receita,
  ticket,
  afiliados,
  saldo,
  grafico_tipo_pagamento,
  relatorios,
  grafico_evolucao_vendas,
  grafico_mais_vendidos,
  emptyStateType = null,
  loading,
  hideInfos,
  period,
  loadingBalance,
  ...rest
}) => {
  const [mediaActive, setMediaActive] = useState(false);

  const handleRedirect = () => {
    window.location.href =
      "https://dashboard.ticketandgo.com.br/#/admin/financial/summary";
  };

  const cardsPerformaceMemo = useCardsPerformance(
    receita,
    ticket,
    afiliados,
    saldo,
    hideInfos,
    handleRedirect,
    loadingBalance
  );

  const PaymentMetricsData = usePaymentMetricData(relatorios);

  const graphData = useGraphData(grafico_tipo_pagamento);

  const graphDataEvolution = useDataEvolution(
    grafico_evolucao_vendas,
    hideInfos,
    period,
    mediaActive
  );

  const graphBestSellers = useGraphBestSellers(
    grafico_mais_vendidos,
    hideInfos
  );

  if (loading) {
    return <TabAllSalesSkeleton />;
  }

  if (!loading && emptyStateType === "blank") {
    <EmptyStateAllSales />;
  }

  if (!loading && emptyStateType === "notfound") {
    return (
      <Notfound
        title={"Nenhum evento ou produto encontrado."}
        subtitle={
          "Nenhum evento ou produto encontrado no período selecionado, reveja o filtro de período e tente novamente."
        }
      />
    );
  }

  const handleClickMedia = () => {
    setMediaActive(!mediaActive);
  };

  return (
    <>
      {!loading && !emptyStateType && (
        <>
          <ScrollerWrapper>
            {cardsPerformaceMemo.map((card) => (
              <CardToggleInfo key={card.title} boxShadowTooltip {...card} />
            ))}
          </ScrollerWrapper>

          <Box
            sx={{
              borderTop: "1px solid #22222214",
              paddingTop: "1rem",
              marginTop: "2rem",
            }}
          >
            <Title style={{ marginBottom: "1rem" }}>Relatórios</Title>

            <Grid container spacing={4}>
              <Grid item xs={12} lg={5}>
                <CardPaymentsForms {...graphData} hideInfos={hideInfos} />
              </Grid>

              <Grid
                item
                xs={12}
                lg={7}
                display={["none", "none", "none", "block"]}
              >
                <CardBestSellers {...graphBestSellers} />
              </Grid>
            </Grid>

            <PaymentMetrics data={PaymentMetricsData} hideInfos={hideInfos} />

            <Box display={["none", "none", "none", "block"]}>
              <CardEvolutionSales
                {...graphDataEvolution}
                onClickMedia={handleClickMedia}
                mediaActive={mediaActive}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default TabAllSales;
