import { Box, CardContent, Skeleton } from "@mui/material";
import { DoughnutChart } from "TGComponents/global/charts/DoughnutChart";
import React from "react";
import {
  Card,
  BoxGraph,
  BoxLegend,
  CardBody,
  Legend,
  LegendItem,
  LegendLabel,
  LegendTotal,
  Title,
} from "./styles";

const CardPaymentsFormsSkeleton = ({ title, legends }) => {
  const data = {
    labels: ["Pagantes", "Gratuitos"],
    datasets: [
      {
        data: [1307, 230, 953, 276],
        backgroundColor: ["#53A462", "#F28F0D", "#D72B2B", "#00A7F0"],
        borderColor: ["#53A462", "#F28F0D", "#D72B2B", "#00A7F0"],
        borderWidth: 1,
        weight: 4,
      },
      {
        data: [1307, 230, 953, 276],
        backgroundColor: ["#53A462", "#F28F0D", "#D72B2B", "#FFF"],
        borderColor: ["#53A462", "#F28F0D", "#D72B2B", "#FFF"],
        borderWidth: 1,
        weight: 3,
      },
      {
        data: [1307, 230, 953, 276],
        backgroundColor: ["#53A462", "#F28F0D", "#FFF", "#FFF"],
        borderColor: ["#53A462", "#F28F0D", "#FFF", "#FFF"],
        borderWidth: 1,
        weight: 2,
      },
      {
        data: [1307, 230, 953, 276],
        backgroundColor: ["#53A462", "#FFF", "#FFF", "#FFF"],
        borderColor: ["#53A462", "#FFF", "#FFF", "#FFF"],
        borderWidth: 1,
      },
    ],
  };

  const options = {};
  return (
    <>
      <Card>
        <CardContent>
          <Title>{title}</Title>
          <CardBody>
            <BoxGraph>
              <DoughnutChart {...{ options, data }} />
            </BoxGraph>
            <BoxLegend>
              <Skeleton
                variant="rounded"
                width={116}
                height={27}
                sx={{ marginTop: "1rem" }}
              />
              <Legend>
                {legends.map((leg) => (
                  <LegendItem key={leg.id}>
                    <LegendLabel>
                      <Box display={"flex"} flexDirection={"column"}>
                        <Skeleton variant="rounded" width={126} height={14} />
                        <Skeleton
                          variant="rounded"
                          width={100}
                          height={11}
                          sx={{ marginTop: "5px" }}
                        />
                      </Box>
                      <LegendTotal>{leg.percent}%</LegendTotal>
                    </LegendLabel>
                  </LegendItem>
                ))}
              </Legend>
            </BoxLegend>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardPaymentsFormsSkeleton;
