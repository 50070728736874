import { useMemo } from "react";

import {
  capitalizeFirstLetter,
  parseBrazilianNumber,
  formatToBrazilianCurrency,
} from "lib/helpers/format";

import { calculatePercentageOfTotal } from "lib/helpers/numbers";

const useGraphData = (grafico_tipo_pagamento) => {
  return useMemo(() => {
    if (!grafico_tipo_pagamento)
      return {
        options: {},
        data: {
          labels: [],
          datasets: [],
        },
      };

    const total = grafico_tipo_pagamento?.reduce(
      (accumulator, currentValue) =>
        Number(accumulator) + Number(currentValue.quantidade),
      0
    );

    const legendMapped = grafico_tipo_pagamento?.map((graphType, index) => {
      return {
        id: index,
        label: capitalizeFirstLetter(graphType.tipo_pagamento),
        quantity:
          `${graphType.quantidade} • ${formatToBrazilianCurrency(graphType.venda_acumulada)}` ||
          0,
        percent: calculatePercentageOfTotal(graphType.quantidade, total) || 0,
      };
    });

    const labels = grafico_tipo_pagamento?.map((graph) => graph.tipo_pagamento);

    const datasetsValues = grafico_tipo_pagamento?.map((graph) => ({
      id: Number(graph.quantidade),
      nested: {
        value: parseBrazilianNumber(graph.venda_acumulada),
      },
    }));

    const backgroundColors = ["#53A462", "#F5AA2A", "#D72B2B", "#00A7F0"];

    const datasets = Array(backgroundColors.length)
      .fill()
      .map((_, i) => {
        const bgColors = backgroundColors.map((color, index) =>
          index < backgroundColors.length - i ? color : "#FFF"
        );

        const borderColors = bgColors;

        return {
          data: datasetsValues,
          backgroundColor: bgColors,
          borderColor: borderColors,
          borderWidth: 1,
          weight: 4 - i,
        };
      });

    return {
      total: total || 0,
      legend: legendMapped,

      data: {
        labels,
        datasets,
      },
    };
  }, [grafico_tipo_pagamento]);
};

export default useGraphData;
