// import { Container } from './styles';

const ExportIcon = ({ width = 16, height = 16, color = '#222222' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_9301_4010)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.5573 1.44332C14.7917 1.67763 14.7917 2.05753 14.5573 2.29184L9.09067 7.75848C8.85635 7.99279 8.47645 7.99279 8.24214 7.75847C8.00783 7.52416 8.00783 7.14426 8.24214 6.90995L13.7088 1.44331C13.9431 1.209 14.323 1.209 14.5573 1.44332Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.8672 1.33242C10.8672 1.00105 11.1358 0.732422 11.4672 0.732422H14.6672C14.9986 0.732422 15.2672 1.00105 15.2672 1.33242V4.53242C15.2672 4.86379 14.9986 5.13242 14.6672 5.13242C14.3358 5.13242 14.0672 4.86379 14.0672 4.53242V1.93242H11.4672C11.1358 1.93242 10.8672 1.66379 10.8672 1.33242Z"
          fill={color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.99344 1.99149C2.91102 1.07392 4.2752 0.732422 6.00104 0.732422H7.33437C7.66575 0.732422 7.93438 1.00105 7.93438 1.33242C7.93438 1.66379 7.66575 1.93242 7.33437 1.93242H6.00104C4.39355 1.93242 3.4244 2.25759 2.84197 2.84002C2.25955 3.42244 1.93438 4.3916 1.93438 5.99909V9.99909C1.93438 11.6066 2.25955 12.5757 2.84197 13.1582C3.4244 13.7406 4.39355 14.0658 6.00104 14.0658H10.001C11.6085 14.0658 12.5777 13.7406 13.1601 13.1582C13.7425 12.5757 14.0677 11.6066 14.0677 9.99909V8.66575C14.0677 8.33438 14.3363 8.06575 14.6677 8.06575C14.9991 8.06575 15.2677 8.33438 15.2677 8.66575V9.99909C15.2677 11.7249 14.9262 13.0891 14.0086 14.0067C13.0911 14.9243 11.7269 15.2658 10.001 15.2658H6.00104C4.2752 15.2658 2.91102 14.9243 1.99344 14.0067C1.07587 13.0891 0.734375 11.7249 0.734375 9.99909V5.99909C0.734375 4.27324 1.07587 2.90907 1.99344 1.99149Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_9301_4010">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExportIcon;
