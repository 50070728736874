import React from "react";

import { Box, CardContent, Skeleton } from "@mui/material";
import { Card } from "./styles";

const CardTicketSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ mb: "16px" }}
        >
          <Skeleton
            variant="rounded"
            width="100%"
            height={27}
            sx={{ maxWidth: "200px" }}
          />

          <Skeleton
            variant="rounded"
            width={"100%"}
            height={27}
            sx={{ maxWidth: "250px" }}
          />
        </Box>

        <Skeleton
          variant="rounded"
          width="100%"
          height={20}
          sx={{ maxWidth: "170px", mb: "16px" }}
        />

        <Skeleton
          variant="rounded"
          width="100%"
          height={20}
          sx={{ maxWidth: "120px", mb: "16px" }}
        />

        <Skeleton variant="rounded" width="100%" height={20} />
      </CardContent>
    </Card>
  );
};

export default CardTicketSkeleton;
