import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Title, ScrollerWrapper } from "./styles";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  TextField,
} from "@mui/material";
import {
  CardBestSellers,
  CardEvolutionSales,
  CardPaymentsForms,
  CardFreeAndPaid,
  PaymentMetricsEvent,
  EmptyStateEvents,
  Notfound,
} from "../..";
import { CardToggleInfo } from "TGComponents/global/cards";
import {} from "lib/helpers/numbers";
import { debounce } from "lodash";
import { useGetData } from "hooks/getData";
import TabEventsSkeleton from "./TabEventsSkeleton";
import { buildApiUrl } from "pages/admin/dashboard/Dashboard";
import * as searchActions from "../../../../../../actions/searchActions";
import { useDispatch, useSelector } from "react-redux";
import { SearchIcon } from "TGComponents/icons";
import {
  useCardsPerformance,
  useDataEvolution,
  useFreeAndPaid,
  useGraphBestSellers,
  useGraphData,
  usePaymentMetricData,
} from "./hooks";

export const useFetchSearchEvents = (search) => {
  const apiUrl = buildApiUrl("api/v2", "event", {
    page: 1,
    perPage: 20,
    orderBy: "cadastro,desc",
    name: search,
  });

  return useGetData(apiUrl, "Error fetching data", {}, false, true, false);
};

const TabEvents = ({
  receita,
  ticket,
  afiliados,
  saldo,
  emptyStateType = null,
  grafico_tipo_pagamento,
  relatorios,
  grafico_evolucao_vendas,
  grafico_mais_vendidos,
  loading,
  hideInfos,
  setGetDataParams,
  loadingType,
  period,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { searchDashboard } = useSelector((state) => state.search);

  const [search, setSearch] = useState("");
  const [mediaActive, setMediaActive] = useState(false);

  const { data: searchData, fetchData: fetchDataSearch } =
    useFetchSearchEvents(search);

  const cardsPerformaceMemo = useCardsPerformance(
    receita,
    ticket,
    afiliados,
    hideInfos
  );

  const PaymentMetricsData = usePaymentMetricData(relatorios);

  const graphData = useGraphData(grafico_tipo_pagamento);

  const graphDataEvolution = useDataEvolution(
    grafico_evolucao_vendas,
    hideInfos,
    period,
    mediaActive
  );

  const graphBestSellers = useGraphBestSellers(
    grafico_mais_vendidos,
    hideInfos
  );

  const graphFreeAndPaid = useFreeAndPaid(relatorios, hideInfos);

  const searchResultMemo = useMemo(() => {
    if (!searchData) return [];

    const mappedSearch = searchData.data.eventos.map((event) => ({
      label: event.nome,
      value: event.uuid,
    }));

    return mappedSearch;
  }, [searchData]);

  const handleSearch = useCallback(
    (e, value) => {
      setSearch(value);
    },
    [setSearch]
  );

  const loadSearchEvents = useCallback(async () => {
    await fetchDataSearch();
  }, [fetchDataSearch]);

  const debouncedLoadSearchEvents = useCallback(
    debounce(() => {
      loadSearchEvents();
    }, 500),
    [loadSearchEvents]
  );

  useEffect(() => {
    if (search) {
      debouncedLoadSearchEvents(search);
    }

    return () => {
      debouncedLoadSearchEvents.cancel();
    };
  }, [search, debouncedLoadSearchEvents]);

  const handleChangeSearch = useCallback(
    (event, field) => {
      dispatch(searchActions.setSearchDashboard(field.label));
      setGetDataParams((prevState) => ({
        ...prevState,
        uuid_produto: field.value,
      }));
    },
    [dispatch, setGetDataParams]
  );

  const handleClickMedia = () => {
    setMediaActive(!mediaActive);
  };

  if (loading && loadingType === "default") {
    return (
      <>
        <Skeleton
          variant="rounded"
          sx={{ marginBottom: "20px", marginTop: "10px" }}
          width={"100%"}
          height={40}
        />
        <TabEventsSkeleton />
      </>
    );
  }

  if (emptyStateType === "blank" && !search) {
    <EmptyStateEvents />;
  }

  return (
    <>
      <Box flex={1} width="100%" padding={"0px"} sx={{ marginBottom: "32px" }}>
        <Autocomplete
          freeSolo
          id="search-products"
          options={searchResultMemo}
          value={searchDashboard}
          onInputChange={handleSearch}
          onChange={handleChangeSearch}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Busque um evento pelo nome"
              sx={{
                minWidth: "66px !important",
                border: "0px solid transparent !important",
                "& .MuiInputBase-root fieldset": {
                  border: "1px solid #22222214",
                  borderRadius: "480px",
                  backgroundColor: "#c1c1c114",
                },
                "& .MuiInputBase-input::placeholder": {
                  fontSize: "14px !important",
                  color: "rgba(0, 0, 0, 0.79) !important",
                },
                "& .MuiInputBase-root": {
                  width: "100%",
                  minHeight: "48px !important",
                  bgcolor: "#fff !important",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-input": {
                  width: "100%",
                  bgcolor: "#fff !important",
                  border: "0px solid transparent !important",
                },
                "& .MuiIconButton-root": {
                  display: "none",
                },
                "& .MuiInputBase-adornedStart svg": {
                  fill: "#222",
                },

                "&.Mui-focused .MuiInputBase-adornedStart svg": {
                  fill: "#00b2ff !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{ marginRight: "10px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                startAdornment: (
                  <Box mr="12px">
                    <SearchIcon size={24} />
                  </Box>
                ),
              }}
            />
          )}
        />
      </Box>

      {loading && loadingType === "loadingSearch" && <TabEventsSkeleton />}

      {emptyStateType === "notfound" && (
        <Notfound
          title={"Nenhum evento ou produto encontrado."}
          subtitle={
            "Nenhum evento ou produto encontrado no período selecionado, reveja o filtro de período e tente novamente."
          }
        />
      )}

      <ScrollerWrapper>
        {cardsPerformaceMemo.map((card) => (
          <CardToggleInfo key={card.title} {...card} />
        ))}
      </ScrollerWrapper>

      <Box
        sx={{
          borderTop: "1px solid #22222214",
          paddingTop: "1rem",
          marginTop: "2rem",
        }}
      >
        <Title style={{ marginBottom: "1rem" }}>Relatórios</Title>

        <Grid container spacing={4} sx={{ marginBottom: "20px" }}>
          <Grid item xs={12} lg={5}>
            <CardPaymentsForms {...graphData} hideInfos={hideInfos} />
          </Grid>

          <Grid item xs={12} lg={7}>
            <CardBestSellers {...graphBestSellers} />
          </Grid>

          <Grid item xs={12} lg={7}>
            <CardFreeAndPaid {...graphFreeAndPaid} />
          </Grid>

          <Grid item xs={12} lg={5}>
            <PaymentMetricsEvent
              data={PaymentMetricsData}
              hideInfos={hideInfos}
            />
          </Grid>
        </Grid>

        <CardEvolutionSales
          {...graphDataEvolution}
          onClickMedia={handleClickMedia}
          mediaActive={mediaActive}
        />
      </Box>
    </>
  );
};

export default TabEvents;
