const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#fff";
    tooltipEl.style.borderRadius = "0 16px 16px 16px";
    tooltipEl.style.boxShadow = "0px 4px 8px 0px #0000001F";
    tooltipEl.style.color = "#222222";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0%)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.width = "200px";
    tooltipEl.style.maxWidth = "100%";
    tooltipEl.style.zIndex = "1000";

    const wrapper = document.createElement("table");
    wrapper.style.margin = "0px";

    tooltipEl.appendChild(wrapper);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export { getOrCreateTooltip };
