export const Close = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9863_25900)">
        <path
          d="M17.1647 8.10867C17.5162 7.7572 17.5162 7.18735 17.1647 6.83587C16.8132 6.4844 16.2434 6.4844 15.8919 6.83587L12.0003 10.7274L8.10866 6.83587C7.75718 6.4844 7.18734 6.4844 6.83587 6.83587C6.4844 7.18735 6.4844 7.7572 6.83587 8.10867L10.7275 12.0002L6.83587 15.8918C6.4844 16.2433 6.4844 16.8131 6.83587 17.1646C7.18734 17.5161 7.75718 17.5161 8.10866 17.1646L12.0003 13.273L15.8919 17.1646C16.2434 17.5161 16.8132 17.5161 17.1647 17.1646C17.5162 16.8131 17.5162 16.2433 17.1647 15.8918L13.2731 12.0002L17.1647 8.10867Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_9863_25900">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
