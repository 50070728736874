import appConfig from "../config";
import { store } from "../store";
import * as userActions from "../actions/userActions";
import * as notificationActions from "../actions/notificationActions";

import { v4 as uuidv4 } from "uuid";

const fileName = (ext = ".xlsx") => {
  const uid = uuidv4().split("-").pop();
  return `${uid}${ext}`;
};

const Requester = async (endpoint, payload = {}) => {
  const { user, meeting } = store.getState();

  const server = appConfig[appConfig.env].api;

  const headers = {
    ...payload,
    method: payload.method ?? "GET",
    headers: payload.headers ?? {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: payload.body ? JSON.stringify(payload.body) : "",
  };

  /*if (meeting?.id) {
        headers.headers['X-Meetingid'] = meeting.id
    }

    headers.headers['withCredentials'] = true*/

  if (user?.access_token) {
    headers.headers["Authorization"] = `Bearer ${user.access_token}`;
  }

  if (headers.method === "GET") {
    delete headers["body"];
  }

  try {
    let ret;

    //if(endpoint !== '/api/auth/login'){

    ret = await fetch(`${server}${endpoint}`, headers);
    //}
    /*else{
            await fetch(`${server}/sanctum/csrf-cookie`, {method: 'GET', withCredentials: true});
            ret = await fetch(`${server}${endpoint}`, headers);
        }*/

    if (ret.status === 401) {
      store.dispatch(userActions.logoff());
      store.dispatch(
        notificationActions.error(
          "Você foi desconectado. Realize novamente seu login"
        )
      );
    } else {
      /*const newAccessToken = ret.headers.get('authorization')
    
            if(newAccessToken){
                store.dispatch(userActions.applyUser({access_token: newAccessToken.replace('Bearer','')}))
            }*/
    }

    //reponded with file
    /*if(contentDisposition.contains('attachment; filename')){
            const fileName = contentDisposition.match(/\".*\"/).join().replace(/\"/g,'')

            console.log(fileName)
            alert(fileName)
        }*/

    let result = await ret.json();

    return result;
  } catch (e) {
    return { status: false };
  }
};

const Download = async (endpoint, payload = {}, ext = ".xlsx") => {
  const { user } = store.getState();

  const server = appConfig[appConfig.env].api;

  const headers = {
    ...payload,
    method: payload.method ?? "GET",
    headers: payload.headers ?? {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: payload.body ? JSON.stringify(payload.body) : "",
  };

  /*if (meeting?.id) {
        headers.headers['X-Meetingid'] = meeting.id
    }

    headers.headers['withCredentials'] = true*/

  if (user?.access_token) {
    headers.headers["Authorization"] = `Bearer ${user.access_token}`;
  }

  if (headers.method === "GET") {
    delete headers["body"];
  }

  try {
    let ret;

    ret = await fetch(`${server}${endpoint}`, headers);

    if (ret.status === 401) {
      store.dispatch(userActions.logoff());
      store.dispatch(
        notificationActions.error(
          "Você foi desconectado. Realize novamente seu login"
        )
      );
    }

    const blob = await ret.blob();

    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName(ext);
    a.click();

    return true;
  } catch (e) {
    console.log(e);
    return { status: false };
  }
};

const RequesterV2 = async (endpoint, payload = {}) => {
  const { user, meeting } = store.getState();

  const server = appConfig[appConfig.env].apiv2;

  const headers = {
    ...payload,
    method: payload.method ?? "GET",
    headers: payload.headers ?? {
      "Content-Type": "application/json",
      Accept: "application/json",
      uuidProdutora: user.roles[0].uuid_produtora,
    },
    body: payload.body ? JSON.stringify(payload.body) : "",
  };

  /*if (meeting?.id) {
        headers.headers['X-Meetingid'] = meeting.id
    }

    headers.headers['withCredentials'] = true*/

  if (user?.access_token) {
    headers.headers["Authorization"] = `Bearer ${user.access_token}`;
  }

  if (headers.method === "GET") {
    delete headers["body"];
  }

  try {
    let ret;

    //if(endpoint !== '/api/auth/login'){

    ret = await fetch(`${server}${endpoint}`, headers);
    //}
    /*else{
            await fetch(`${server}/sanctum/csrf-cookie`, {method: 'GET', withCredentials: true});
            ret = await fetch(`${server}${endpoint}`, headers);
        }*/

    if (ret.status === 401) {
      store.dispatch(userActions.logoff());
      store.dispatch(
        notificationActions.error(
          "Você foi desconectado. Realize novamente seu login"
        )
      );
    } else {
      /*const newAccessToken = ret.headers.get('authorization')
    
            if(newAccessToken){
                store.dispatch(userActions.applyUser({access_token: newAccessToken.replace('Bearer','')}))
            }*/
    }

    //reponded with file
    /*if(contentDisposition.contains('attachment; filename')){
            const fileName = contentDisposition.match(/\".*\"/).join().replace(/\"/g,'')

            console.log(fileName)
            alert(fileName)
        }*/

    let result = await ret.json();

    return result;
  } catch (e) {
    return { status: false };
  }
};

export { Download };
export { RequesterV2 };
export default Requester;
