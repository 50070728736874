import { css, styled } from "@mui/material";
import pallete from "TGComponents/theme/pallete";

export const CardsContainer = styled("div")`
  display: grid;
  align-items: center;
  gap: 20px;
  width: 100%;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ReportsContainer = styled("div")`
  border-top: 1px solid #22222214;
  padding-top: 1rem;
  margin-top: 2rem;
  width: 100%;
`;

export const Title = styled("div")`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${pallete.base.black};
  margin-bottom: 1rem;
`;

export const ReportsBox = styled("div")`
  ${({ theme }) => css`
    display: grid;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      grid-template-columns: 360px auto;
      gap: 20px;
    }
  `}
`;

export const BoxGraph = styled("div")`
  width: 100%;
  max-width: 100%;

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
