export const EyeSlash = ({ size = 24, color = "#222222" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_964_30132)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.5239 2.20697C23.8799 1.83938 23.8799 1.24339 23.5239 0.875794C23.168 0.508199 22.5908 0.508199 22.2348 0.875794L18.7913 4.43157C16.9726 3.13053 14.905 2.40944 12.751 2.40944C8.78921 2.40944 5.19733 4.82155 2.75526 8.78545C2.18379 9.71031 1.92879 10.8864 1.92879 12.0053C1.92879 13.1242 2.18378 14.3003 2.75523 15.2251C3.49595 16.4257 4.34831 17.4896 5.27966 18.3838L1.97793 21.7932C1.62194 22.1608 1.62194 22.7568 1.97793 23.1244C2.33391 23.492 2.91108 23.492 3.26706 23.1244L10.833 15.3117L10.8463 15.2977L15.9431 10.0347C15.9481 10.0297 15.9531 10.0247 15.958 10.0196L23.5239 2.20697ZM15.2489 8.08956L17.4773 5.78844C16.0069 4.80777 14.3951 4.29201 12.751 4.29201C9.56236 4.29201 6.4899 6.23047 4.29315 9.79649L4.2924 9.79772C3.95258 10.3475 3.75191 11.1478 3.75191 12.0053C3.75191 12.8629 3.95258 13.6632 4.29239 14.2129C4.96923 15.3099 5.7413 16.2649 6.56979 17.0516L8.96395 14.5794C8.48973 13.8403 8.21331 12.9551 8.21331 11.9999C8.21331 9.4092 10.2419 7.31441 12.7508 7.31441C13.6759 7.31441 14.5331 7.59986 15.2489 8.08956ZM10.0364 11.9999C10.0364 10.4489 11.2488 9.19699 12.7508 9.19699C13.1685 9.19699 13.5621 9.29343 13.9144 9.46718L10.2981 13.2014C10.1298 12.8376 10.0364 12.4312 10.0364 11.9999Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          d="M20.3328 7.00996C20.7251 6.68413 21.2989 6.74838 21.6145 7.15346C22.0061 7.65623 22.3915 8.19739 22.7474 8.77649C23.3183 9.70114 23.573 10.8766 23.573 11.9949C23.573 13.1139 23.318 14.29 22.7465 15.2148C20.3045 19.1787 16.7126 21.5908 12.7508 21.5908C11.3755 21.5908 10.0301 21.2905 8.77113 20.7431C8.30714 20.5414 8.08938 19.9894 8.28474 19.5103C8.4801 19.0312 9.01461 18.8063 9.4786 19.0081C10.5289 19.4647 11.6345 19.7083 12.7508 19.7083C15.9395 19.7083 19.0119 17.7698 21.2087 14.2038L21.2094 14.2025C21.5492 13.6528 21.7499 12.8525 21.7499 11.9949C21.7499 11.1374 21.5492 10.337 21.2094 9.78728L21.2081 9.78522C20.8956 9.2766 20.5517 8.79281 20.1939 8.33338C19.8783 7.9283 19.9406 7.33578 20.3328 7.00996Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          d="M17.2024 12.9029C17.2937 12.3916 16.9663 11.9008 16.4712 11.8065C15.9761 11.7122 15.5008 12.0502 15.4095 12.5615C15.2147 13.6523 14.3408 14.5547 13.2844 14.7559C12.7893 14.8501 12.462 15.341 12.5533 15.8522C12.6446 16.3635 13.1199 16.7015 13.615 16.6072C15.4149 16.2645 16.8705 14.7614 17.2024 12.9029Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_964_30132">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
