import styled, { css } from "styled-components";
import { Card as CardMUI } from "@mui/material";
import pallete from "TGComponents/theme/pallete";

const wrapperModifiersCard = {
  info: () => css`
    background-color: #f2f9fc;
  `,
  normal: () => css`
    background-color: #f7f9fa;
  `,
};

export const Card = styled(CardMUI)`
  ${({ bgColor }) => css`
    border: 1px solid ${pallete.neutral[60]};
    box-shadow: none;
    height: 100%;
    ${wrapperModifiersCard[bgColor || "normal"]()}
  `}
`;

export const Title = styled.h4`
  color: ${pallete.base.blackAlpha};
  font-weight: 400;
  font-size: 0.75rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Total = styled.h5`
  color: ${pallete.base.black};
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const Footer = styled.div`
  font-size: 0.875rem;
  background: none;
  border: 0;
  font-weight: 500;
  color: ${pallete.base.blackAlpha};
  padding: 0;
`;

const wrapperModifiers = {
  up: () => css`
    border-bottom: 7px solid rgba(83, 164, 98, 1);
  `,
  down: () => css`
    border-top: 7px solid rgba(215, 43, 43, 1);
  `,
  normal: () => css`
    width: 10px;
    height: 10px;
    background-color: ${pallete.base.blackAlpha};
  `,
};

const wrapperModifiersColor = {
  up: () => css`
    color: rgba(83, 164, 98, 1);
  `,
  down: () => css`
    color: rgba(215, 43, 43, 1);
  `,
  normal: () => css`
    color: ${pallete.base.blackAlpha};
  `,
};

export const IconArrow = styled.div`
  ${({ type, colorValue }) => css`
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    ${wrapperModifiersColor[colorValue || "normal"]()}

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      margin-right: 0.5rem;
      ${wrapperModifiers[type || "normal"]()}
    }
  `}
`;

export const ButtonHide = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin-right: 1rem;
`;

export const LinkButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin-top: 0.5rem;
  color: #00a7f0;
  font-size: 0.75rem;
`;

export const HideInfos = css`
  -webkit-text-security: disc;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-width: 2px;
  letter-spacing: 9px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  width: 100%;
  overflow: hidden;
  display: flex;
`;

export const Content = styled.div`
  ${({ hideContent }) => hideContent && HideInfos}
`;
