import React from "react";

import { CardContent, Skeleton } from "@mui/material";
import { Card } from "./styles";

const CardStatusSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Skeleton
          variant="rounded"
          width="100%"
          height={20}
          sx={{ mb: "16px" }}
        />
        <Skeleton variant="rounded" width={115} height={25} />
        <Skeleton
          variant="rounded"
          width="100%"
          height={25}
          sx={{ my: "20px" }}
        />
      </CardContent>
    </Card>
  );
};

export default CardStatusSkeleton;
