import { Box, CardContent } from "@mui/material";
import React from "react";
import { Card, BoxGraph, CardBody, Title, Labels, LabelsItem } from "./styles";
import { BarChart } from "TGComponents/global/charts/BarChart";

const CardFreeAndPaid = ({ options, data }) => {
  return (
    <>
      <Card>
        <CardContent>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title>Gratuitos/pagos</Title>
            <Labels>
              <LabelsItem>Ingressos pagos</LabelsItem>
              <LabelsItem>Ingressos gratuitos</LabelsItem>
            </Labels>
          </Box>
          <CardBody>
            <BoxGraph>
              <BarChart variant="secondary" {...{ options, data }} />
            </BoxGraph>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardFreeAndPaid;
