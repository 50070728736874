import { useMemo } from "react";
import { parseBrazilianNumber } from "lib/helpers/format";
import { formatNumberToK } from "lib/helpers/numbers";
import { fillArrayToSize } from "../../TabAllSales/hooks/useGraphBestSellers";

const useGraphBestSellers = (grafico_mais_vendidos, hideInfos) => {
  return useMemo(() => {
    if (!grafico_mais_vendidos)
      return {
        options: {},
        data: {
          labels: [],
          datasets: [],
        },
      };

    const graphBestSellersResult = grafico_mais_vendidos;

    const legend = graphBestSellersResult?.map((graph) => graph.evento_nome);

    const mappedLabels = graphBestSellersResult?.map((graph) => "Produto");

    const labels = [...new Set(mappedLabels)];

    const mappedLabelsGraph = graphBestSellersResult?.map(
      (graph) => graph.evento_nome
    );

    const labelsGraph = fillArrayToSize(mappedLabelsGraph, 4);

    const datasets = graphBestSellersResult?.map((graph, i) => ({
      id: parseBrazilianNumber(graph.venda_acumulada),
      nested: {
        value: Number(graph.quantidade),
      },
    }));

    return {
      options: {
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return !hideInfos ? `R$ ${formatNumberToK(value)}` : "•••••••";
              },
            },
          },
        },
      },
      legend,
      labels: labels,
      data: {
        labels: labelsGraph,
        datasets: [
          {
            axis: "y",
            data: datasets,
            fill: false,
            borderColor: "#F5AA2A",
            backgroundColor: "#F5AA2A",
            borderRadius: 4,
            tension: 0.1,
          },
        ],
      },
    };
  }, [grafico_mais_vendidos, hideInfos]);
};

export default useGraphBestSellers;
