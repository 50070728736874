import { Box, CardContent, Skeleton } from "@mui/material";
import React from "react";
import {
  Card,
  BoxGraph,
  CardBody,
  Title,
  Legend,
  Labels,
  LabelsItem,
  LegendSkeleton,
} from "./styles";
import { BarChart } from "TGComponents/global/charts/BarChart";

const CardBestSellersSkeleton = ({ title }) => {
  const options = {
    scales: {
      y: {
        display: false,

        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const data = {
    labels: [
      "Introdução ao Community Management",
      "Fazendinha de parelheiros",
      "Introdução ao Community Management voltado a marketing digital",
      "Fazendinha de parelheiros - Brasília",
    ],
    datasets: [
      {
        data: [65, 0, 0, 0],
        fill: false,
        backgroundColor: "#F2C200",
        borderColor: "#F2C200",
        borderRadius: 4,
        tension: 0.1,
        borderWidth: 30,
      },
      {
        data: [0, 50, 0, 0],
        fill: false,
        borderColor: "rgb(0, 178, 255)",
        backgroundColor: "#53A462",
        borderRadius: 4,
        tension: 0.1,
      },

      {
        data: [0, 0, 70, 0],
        fill: false,
        borderColor: "rgb(0, 178, 255)",
        backgroundColor: "#F2C200",
        borderRadius: 4,
        tension: 0.1,
      },

      {
        data: [0, 0, 0, 91],
        fill: false,
        borderColor: "rgb(0, 178, 255)",
        backgroundColor: "#53A462",
        borderRadius: 4,
        tension: 0.1,
      },
    ],
  };
  return (
    <>
      <Card>
        <CardContent>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title>{title}</Title>
            <Labels>
              <LabelsItem>Produto</LabelsItem>
              <LabelsItem>Evento</LabelsItem>
            </Labels>
          </Box>
          <CardBody>
            <Legend>
              <Skeleton variant="rounded" width={"100%"} height={14} />
              <Skeleton
                variant="rounded"
                width={"100%"}
                sx={{ mt: "30px" }}
                height={14}
              />
              <Skeleton
                variant="rounded"
                width={"100%"}
                sx={{ mt: "30px" }}
                height={14}
              />
              <Skeleton
                variant="rounded"
                width={"100%"}
                sx={{ mt: "30px" }}
                height={14}
              />
            </Legend>
            <Box>
              <BoxGraph>
                <BarChart {...{ options, data }} />
              </BoxGraph>

              <LegendSkeleton>
                <span>0</span>
                <Skeleton variant="rounded" width={43} height={14} />
                <Skeleton variant="rounded" width={43} height={14} />
                <Skeleton variant="rounded" width={43} height={14} />
                <Skeleton variant="rounded" width={43} height={14} />
                <Skeleton variant="rounded" width={43} height={14} />
              </LegendSkeleton>
            </Box>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardBestSellersSkeleton;
