import TGIcon from './TGIcon';
import recoverIcon from 'assets/svgs/financial/recoverIcon.svg';
import assinIcon from 'assets/svgs/financial/assinIcon.svg';
import botIcon from 'assets/svgs/financial/botIcon.svg';

import { Box, Tooltip } from '@mui/material';

const { Typography } = require('@mui/material');
export default function TGTag({
  status,
  id,
  valorDesconto,
  padding = '4px 6px',
  height = '26px',
}) {
  const STATUS_STYLES = {
    //pessoas Afiliadas
    'convite': { title: 'Convite', bgcolor: '#00B2FF', color: '#fff' },
    'recusado': { title: 'Recusado', bgcolor: '#F7EAE5', color: '#FF5858' },
    'ativo': { title: 'Ativo', bgcolor: '#F3FAF4', color: '#53A462' },
    'Ativa': { title: 'Ativo', bgcolor: 'success.80', color: 'success.10' },
    'pendente': { title: 'Pendente', bgcolor: '#F7F7E5', color: '#A77D13' },
    'encerrado': { title: 'Encerrado', bgcolor: '#F7F9FA', color: '#2222228F' },
    //meus programas afiliados
    'desativado': { title: 'Desativado', bgcolor: '#FAF6DC', color: '#F5910D' },
    'Inativo': { title: 'Inativo', bgcolor: '#FAF6DC', color: '#F5910D' },
    'removido': { title: 'Removido', bgcolor: '#FAF0F2', color: '#D72B2B' },
    'expirado': { title: 'Expirado', bgcolor: '#FAF0F2', color: '#D72B2B' },
    'Encerrado': { title: 'Encerrado', bgcolor: '#FAF0F2', color: '#D72B2B' },
    'ativado': { title: 'Ativado', bgcolor: '#E5F7E5', color: '#59A15A' },

    //financial Vendas recuperadas
    'Não autorizado': {
      title: 'Não autorizado',
      bgcolor: 'error.80',
      color: 'error.10',
    },
    'Reembolsado': {
      title: 'Reembolsado',
      bgcolor: 'neutral.60',
      color: 'neutral.30',
    },
    'Reembolsado<br/>ReembolsadoReembolsado': {
      title: 'Reembolsado',
      bgcolor: 'neutral.60',
      color: 'neutral.30',
    },
    'Reembolsado<br/>Reembolsado': {
      title: 'Reembolsado',
      bgcolor: 'neutral.60',
      color: 'neutral.30',
    },
    'Ativo<br/>AtivoAtivo': {
      title: 'Ativo',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'Ativo': {
      title: 'Ativo',
      bgcolor: 'green.50',
      color: 'green.500',
    },
    'Comprador': {
      title: 'Comprador',
      bgcolor: '#EEEEEE',
      color: '#555555',
    },
    'Gratuito': {
      title: 'Gratuito',
      bgcolor: '#EEEEEE',
      color: '#555555',
    },
    'Importado': {
      title: 'Importado',
      bgcolor: '#EEEEEE',
      color: '#555555',
    },
    'Em atraso': {
      title: 'Atrasada',
      bgcolor: 'alert.80',
      color: 'alert.10',
    },
    'Autorizado': {
      title: 'Autorizado',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'Estornada': {
      title: 'Estornada',
      bgcolor: 'alert.80',
      color: 'alert.10',
    },

    //financial
    'EM PROCESSAMENTO': {
      title: 'Processando',
      bgcolor: 'alert.80',
      color: 'alert.10',
    },
    'CONCLUÍDO': {
      title: 'Concluído',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'DISPONIVEL': {
      title: 'Disponível',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'À vencer': {
      title: 'À vencer',
      bgcolor: 'neutral.60',
      color: 'neutral.30',
    },
    'Aguardando': {
      title: 'Aguardando',
      bgcolor: 'alert.80',
      color: 'alert.10',
    },
    'Pago': {
      title: 'Pago',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'Atrasado': {
      title: 'Atrasado',
      bgcolor: 'error.80',
      color: 'error.10',
    },
    'BOLETO GERADO': {
      title: 'Boleto gerado',
      bgcolor: '#E6F5FB',
      color: '#00B2FF',
    },

    'AUTOAPROVADO': {
      title: 'Aprovado',
      bgcolor: 'primary.80',
      color: 'primary.10',
      icon: botIcon,
      tooltip: 'Aprovado automaticamente',
    },
    'COMPRADOR': {
      title: 'Comprador',
      bgcolor: '#E9E6FB',
      color: '#865BFF',
    },
    'VENDEDOR': {
      title: 'Vendedor',
      bgcolor: 'neutral.60',
      color: 'neutral.30',
    },
    'VENCIDA': {
      title: 'Vencida',
      bgcolor: 'neutral.60',
      color: 'neutral.30',
    },
    'PAGAMENTO PARCIAL': {
      title: 'Pagamento parcial',
      bgcolor: 'primary.80',
      color: 'primary.10',
    },
    'Reembolso Realizado': {
      title: 'Aprovado',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'Reembolso Solicitado': {
      title: 'Pendente',
      bgcolor: 'alert.80',
      color: 'alert.10',
    },
    'APROVADO': {
      title: 'Aprovada',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'RECUPERADO': {
      title: 'Aprovada ',
      bgcolor: 'success.80',
      color: 'success.10',
      icon: recoverIcon,
    },
    'ASSINATURA': {
      title: 'Aprovada',
      bgcolor: 'success.80',
      color: 'success.10',
      icon: assinIcon,
    },

    'ESTORNADO': { title: 'Reembolsado', bgcolor: '#EEEEEE', color: '#555555' },
    'CHARGEBACK': { title: 'Chargeback', bgcolor: '#555555', color: '#ffffff' },
    'BOLETO_CANCELADO': {
      title: 'Chargeback',
      bgcolor: '#F7EAE5',
      color: '#FF5858',
    },
    'Cancelada': {
      title: 'Cancelada',
      bgcolor: '#F7EAE5',
      color: '#FF5858',
    },
    'Bloqueado': {
      title: 'Bloqueado',
      bgcolor: '#F7EAE5',
      color: '#A16659',
    },
    'desconto': {
      title: `${valorDesconto}% de desconto`,
      bgcolor: '#EEEEEE',
      color: '#555555',
    },
    'estrangeiro': {
      title: 'Estrangeiro',
      bgcolor: '#CCEAF7',
      color: '#555555',
    },
    'publicado': {
      title: 'Publicado',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'aprovado': {
      title: 'Aprovado',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'Aprovado': {
      title: 'Aprovado',
      bgcolor: 'success.80',
      color: 'success.10',
    },
    'rascunho': { title: 'Rascunho', bgcolor: '#EEE', color: '#333' },
    'Rascunho': {
      title: 'Rascunho',
      bgcolor: '#FAFAFA',
      color: 'rgba(34, 34, 34, 0.56)',
    },
    'pausado': {
      title: 'Pausado',
      bgcolor: 'alert.light',
      color: 'yellow.500',
    },
    'Pausada': {
      title: 'Pausada',
      bgcolor: 'alert.light',
      color: 'yellow.500',
    },
    // 'Inativo': { title: 'Inativo', bgcolor: '#F7F7E5', color: '#EEAB00' },
    'Pausado': { title: 'Pausado', bgcolor: '#F7F7E5', color: '#EEAB00' },
    'habilitado': { title: 'Habilitado', bgcolor: '#EEEEEE', color: '#555555' },
    'desabilitado': {
      title: 'Desabilitado',
      bgcolor: '#EEEEEE',
      color: '#55555564',
    },
    'desconhecido': { title: 'Desconhecido', bgcolor: '#FFF', color: '#000' },
    'bloqueado': { title: 'Bloqueado', bgcolor: '#F7EAE5', color: '#FF5858' },
    'em_processo_de_emissão': {
      title: 'Em emissão',
      bgcolor: '#FFFCEB',
      color: '#555',
    },
    'emitida': { title: 'Emitida', bgcolor: '#EEFCF0', color: '#555' },
    'falha_ao_emitir': {
      title: 'Falha ao emitir',
      bgcolor: '#FFEBEE',
      color: '#555',
    },
    'em_processo_de_cancelamento': {
      title: 'Em cancelamento',
      bgcolor: '#FFFCEB',
      color: '#555',
    },
    'cancelada': { title: 'Cancelada', bgcolor: '#FFEBEE', color: '#555' },
    'falha_ao_cancelar': {
      title: 'Erro ao cancelar',
      bgcolor: '#FFEBEE',
      color: '#555',
    },
    'nao_emitir': { title: 'Não emitir', bgcolor: '#F9F9F9', color: '#555' },
    'enviado_por_email': {
      title: 'E-mail enviado',
      bgcolor: '#EEFCF0',
      color: '#555',
    },
    'aceito': { title: 'Aceito', bgcolor: 'success.80', color: 'success.10' },
    'pendente_coproducao': {
      title: 'Pendente',
      bgcolor: '#F7F7E5',
      color: '#EEAB00',
    },
    'encerrada': { title: 'Encerrada', bgcolor: '#EEEEEE', color: '#555555' },
    'pendente_checkin_online': {
      title: 'Pendente',
      bgcolor: '#FAF6DC',
      color: '#F5910D',
    },
    'Cancelado': {
      title: 'Cancelado',
      bgcolor: '#FAF0F2',
      color: '#D72B2B',
    },
    'Operador_Inativo': {
      title: 'Inativo',
      bgcolor: '#F7EAE5',
      color: '#D72B2B',
    },
  };
  const DEFAULT_ID_STYLE = { title: '', bgcolor: '#EFF7FA', color: '#00B2FF' };

  const { title, bgcolor, color, icon, tooltip } = status
    ? STATUS_STYLES[status] || STATUS_STYLES['desconhecido']
    : { ...DEFAULT_ID_STYLE, title: `${id}` };

  return (
    <Tooltip title={tooltip}>
      <Typography
        borderRadius={'6px'}
        height={height}
        padding={padding}
        textAlign={'center'}
        bgcolor={bgcolor}
        color={color}
        fontSize={'12px'}
        display={'inline'}
        position='relative'
      >
        {title}
        {icon ? (
          <Box position='absolute' right='0px' bottom={'-5px'}>
            <TGIcon src={icon} height='17.68px' width='17.68px' />
          </Box>
        ) : null}
      </Typography>
    </Tooltip>
  );
}

export function TGCustomTag({
  padding = '4px 6px',
  bgcolor = '#fafafa',
  color = '#222222',
  fontWeight,
  title,
  width,
  display = 'inline',
  direction,
}) {
  return (
    <Typography
      borderRadius={'8px'}
      maxHeight={'26px'}
      padding={padding}
      textAlign={'center'}
      justifyContent='center'
      alignItems='center'
      bgcolor={bgcolor}
      fontWeight={fontWeight}
      color={color}
      variant='body-m'
      display={display}
      flexDirection={direction}
      position='relative'
      width={width}
    >
      {title}
    </Typography>
  );
}
