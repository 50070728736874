import { useMemo } from "react";

import {
  formatDateToMonth,
  formatDateToMonthAndDay,
  addAfterDays,
} from "lib/helpers/date";

import { formatToBrl, parseBrazilianNumber } from "lib/helpers/format";
import { getMediaDataGraph } from "lib/helpers/numbers";

const useDataEvolution = (
  grafico_evolucao_vendas,
  hideInfos,
  period,
  mediaActive
) => {
  return useMemo(() => {
    let linDataMedia;

    if (!grafico_evolucao_vendas)
      return {
        options: {},
        data: {
          labels: [],
          datasets: [],
        },
      };

    const isMoreThanSixMonths =
      period === "lastSixMonths" || period === "lastTwelveMonths";

    const arrayDaysComplete =
      period === "today"
        ? grafico_evolucao_vendas
        : addAfterDays(grafico_evolucao_vendas, period);

    const barData = arrayDaysComplete?.map((graph) =>
      parseBrazilianNumber(graph.venda_acumulada)
    );

    if (mediaActive) {
      linDataMedia = getMediaDataGraph(barData, 7);
    }

    const labels = arrayDaysComplete?.map((graph) =>
      !isMoreThanSixMonths
        ? formatDateToMonthAndDay(graph.data_pg)
        : formatDateToMonth(graph.data_pg)
    );

    const values = arrayDaysComplete?.map((graph) => ({
      id: Number(graph.quantidade),
      nested: {
        value: parseBrazilianNumber(graph.venda_acumulada),
      },
    }));

    return {
      options: {
        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return !hideInfos ? "" + formatToBrl(value) : "•••••••";
              },
            },
            grid: {
              display: false,
            },
          },
        },
      },
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            fill: false,
            borderColor: "rgb(0, 178, 255)",
            tension: 0.1,
          },
          ...(mediaActive
            ? [
                {
                  data: linDataMedia,
                  fill: false,
                  borderColor: "#BEE9FA",
                  tension: 0.1,
                  BorderStyle: "dashed",
                },
              ]
            : []),
        ],
      },
    };
  }, [grafico_evolucao_vendas, hideInfos, period, mediaActive]);
};

export default useDataEvolution;
