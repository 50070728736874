import React from "react";

import {
  BoxLeft,
  BoxRight,
  ButtonGroups,
  Container,
  Description,
  Title,
} from "./styles";
import { TGButton } from "TGComponents/global";

const EmptyStateEvents = () => {
  return (
    <>
      <Container>
        <BoxLeft>
          <Title>Hmm, você ainda não criou nenhum evento.</Title>
          <Description>
            Crie eventos personalizados através da nossa plataforma.
          </Description>
          <ButtonGroups>
            <TGButton
              to={`/admin/eventos/ingresso/adicionar?menuTipo=evento&uuid`}
              sx={{
                height: "48px",
                fontSize: "0.875rem",
                textTransform: "none",
                "&:hover": {
                  color: "#fff",
                },
              }}
            >
              Criar meu primeiro evento
            </TGButton>
          </ButtonGroups>
        </BoxLeft>

        <BoxRight>
          <img src={"/images/emptystate/calendar.png"} alt="addSquare" />
        </BoxRight>
      </Container>
    </>
  );
};

export default EmptyStateEvents;
