import { getOrCreateTooltip } from "./getOrCreateTooltip";

const { formatToBrl } = require("lib/helpers/format");

const externalTooltipHandlerBarChart = (context) => {
  const { chart, tooltip } = context;
  const datasets = tooltip.dataPoints[0].raw.nested.value;

  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("thead");

    const tableBody = document.createElement("div");
    tableBody.style.width = "190px";
    tableBody.style.paddingInline = "5px";
    tableBody.style.maxWidth = "100%";

    const formatedTotalValue = formatToBrl(tooltip.dataPoints[0].raw.id);

    const labelTooltip = tooltip.dataPoints[0].label;

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const wrapper = document.createElement("div");
      wrapper.style.display = "flex";
      wrapper.style.flexDirection = "column";

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginTop = "2px";
      span.style.borderRadius = "480px";
      span.style.height = "8px";
      span.style.width = "40px";
      span.style.display = "inline-block";

      const itemTitle = document.createElement("h3");
      itemTitle.innerHTML = labelTooltip;
      itemTitle.style.marginBlock = "8px";
      itemTitle.style.paddingBottom = "8px";
      itemTitle.style.borderBottom = "1px solid #22222214";
      itemTitle.style.fontWeight = "500";
      itemTitle.style.fontSize = "16px";

      const sellsText = document.createElement("p");
      sellsText.innerHTML = datasets + " vendas";
      sellsText.style.fontWeight = "400";
      sellsText.style.fontSize = "12px";
      sellsText.style.color = "#2222228F";
      sellsText.style.marginBottom = "4px";

      const valuesTotalText = document.createElement("p");
      valuesTotalText.innerHTML = formatedTotalValue;
      valuesTotalText.style.fontWeight = "500";
      valuesTotalText.style.fontSize = "14px";
      valuesTotalText.style.color = "#2222228F";
      valuesTotalText.style.marginBottom = "4px";

      wrapper.appendChild(span);
      wrapper.appendChild(itemTitle);
      wrapper.appendChild(sellsText);
      wrapper.appendChild(valuesTotalText);
      tableBody.appendChild(wrapper);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

export { externalTooltipHandlerBarChart };
