import React from "react";
import { LinkPage, ScrollerHorizontal, Title } from "./styles";
import { ArrowRight } from "TGComponents/icons/ArrowRight";
import { CardProduct } from "..";
import { Box, Card } from "@mui/material";
import CardProductOrEventSkeleton from "../CardMyEvents/CardProductOrEventSkeleton";

const CardActiveProducts = ({ data, loading }) => {
  if (loading) {
    return <CardProductOrEventSkeleton title={"Produtos ativos"} />;
  }

  return (
    <Card elevation={0} sx={{ borderRadius: "16px", marginTop: "20px" }}>
      <Box px={["0px", "0px", "30px"]} py={["0px", "0px", "30px"]}>
        <Title>Produtos ativos</Title>
        <ScrollerHorizontal>
          {data?.length > 0 &&
            data.map((item) => (
              <CardProduct
                key={item.id}
                image={item.image || "/images/login/LoginLogoMobile.png"}
                title={item.title}
                category={item.categpry || "Não informada"}
              />
            ))}
        </ScrollerHorizontal>

        <LinkPage to={"/admin/produtoold"}>
          Ver página de produtos
          <ArrowRight color="#00a7f0" />
        </LinkPage>
      </Box>
    </Card>
  );
};

export default CardActiveProducts;
