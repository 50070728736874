import React, { useEffect, useState } from "react";
import { Box, CardContent } from "@mui/material";

import { ButtonHide, Card, Content, Footer, LinkButton, Title } from "./styles";
import { IconArrow } from "./styles";
import { Total } from "./styles";
import { helpIcon } from "assets/svgs";
import { TGTooltip } from "TGComponents/layout/TGSideBar/styles";
import { CardInfoSkeleton } from "TGComponents/global/skeletons";
import { Eye } from "TGComponents/icons/Eye";
import { EyeSlash } from "TGComponents/icons/EyeSlash";

export const CardToggleInfo = ({
  title,
  total,
  footerText,
  onFooterClick,
  value = null,
  type,
  tooltipMessage,
  placement = "top",
  loading,
  colorValue = "normal",
  onHideButton,
  hideInfos,
  bgColor,
  link,
  onLink,
  boxShadowTooltip,
}) => {
  const [hideContent, setHideContent] = useState(hideInfos);
  const handleHideContent = () => {
    setHideContent(!hideContent);
  };

  useEffect(() => {
    setHideContent(hideInfos);
  }, [hideInfos]);

  return (
    <>
      {loading && <CardInfoSkeleton />}

      {!loading && (
        <Card
          bgColor={bgColor}
          sx={{
            borderRadius: "16px",
          }}
        >
          <CardContent>
            <Title>
              {title}
              <Box display={"flex"} alignItems={"center"}>
                {onHideButton && (
                  <ButtonHide type="button" onClick={handleHideContent}>
                    {!hideContent ? <Eye /> : <EyeSlash />}
                  </ButtonHide>
                )}
                <TGTooltip
                  title={tooltipMessage}
                  placement={placement}
                  boxShadowTooltip={boxShadowTooltip}
                >
                  <img
                    src={helpIcon}
                    alt="icon-tooltip"
                    style={{ cursor: "pointer" }}
                  />
                </TGTooltip>
              </Box>
            </Title>

            <Content hideContent={hideContent}>
              <Total>{total}</Total>

              {!!footerText && <Footer>{footerText}</Footer>}

              {value !== null && (
                <IconArrow type={type} colorValue={colorValue}>
                  {value}
                </IconArrow>
              )}
            </Content>

            {link && onLink && (
              <LinkButton type="button" onClick={onLink}>
                {link}
              </LinkButton>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};
