// cardsPerformance.js
import { useMemo } from "react";
import { formatToBrazilianCurrency, formatToBrl } from "lib/helpers/format";

export const createCard = ({
  title,
  total,
  tooltipMessage,
  footerText,
  type,
  value,
  colorValue,
  bgColor,
  link,
  onLink,
  hideInfos,
  loading = false,
}) => ({
  title,
  total,
  tooltipMessage,
  loading,
  footerText,
  type,
  value,
  colorValue,
  bgColor,
  link,
  onLink,
  onHideButton: true,
  hideInfos,
});

const useCardsPerformance = (
  receita,
  ticket,
  afiliados,
  saldo,
  hideInfos,
  handleRedirect,
  loadingBalance
) => {
  return useMemo(() => {
    return [
      createCard({
        title: "Receita",
        total: `${formatToBrazilianCurrency(receita?.receita)}`,
        tooltipMessage:
          "Montante total de receita adquirida no período selecionado.",
        loading: false,
        footerText: `${receita?.total_vendas ?? 0} vendas`,
        type: receita?.indicador,
        value: `${receita?.receita_percentual ?? 0}%`,
        colorValue: receita?.indicador,
        onHideButton: true,
        hideInfos,
      }),
      createCard({
        title: "Ticket médio",
        total: `${formatToBrazilianCurrency(ticket?.ticket_medio)}`,
        tooltipMessage:
          "O ticket médio é a soma de toda a receita, dividida pela quantidade de vendas, ele é um valor médio de cada venda.",
        loading: false,
        footerText: `Min: ${formatToBrazilianCurrency(ticket?.ticket_min)}, Máx: ${formatToBrazilianCurrency(ticket?.ticket_max)}`,
        type: ticket?.indicador,
        value: `${ticket?.ticket_medio_percentual ?? 0}%`,
        colorValue: ticket?.indicador,
        onHideButton: true,
        hideInfos,
      }),
      createCard({
        title: "Vendas por afiliados",
        total: `${formatToBrazilianCurrency(afiliados?.afiliados_vendas)}`,
        tooltipMessage: "Total de vendas que vieram a partir de afiliados.",
        loading: false,
        footerText: `${afiliados?.afiliados_quantidade ?? 0} vendas • ${afiliados?.afiliados_participacao ?? 0} da receita`,
        type: afiliados?.indicador,
        value: `${afiliados?.afiliados_percentual ?? 0}%`,
        colorValue: afiliados?.indicador,
        onHideButton: true,
        hideInfos,
      }),
      createCard({
        title: "Saldo total",
        total: `${formatToBrl(saldo?.valorAReceber)}`,
        tooltipMessage:
          "O valor disponível para saque leva em consideração o dinheiro que ainda não foi sacado no período, incluindo apenas pagamentos já efetuados, portanto, futuras parcelas e boletos pendentes não entram na soma.",
        loading: loadingBalance,
        footerText: `Saque disponível: ${formatToBrl(saldo?.saldoDisponivel)}`,
        bgColor: "info",
        link: "Solicitar saque",
        onLink: handleRedirect,
        onHideButton: true,
        hideInfos,
      }),
    ];
  }, [receita, ticket, afiliados, saldo, hideInfos, handleRedirect]);
};

export default useCardsPerformance;
