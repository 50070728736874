import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { Card as CardMUI } from "@mui/material";
import { radius } from "TGComponents/theme/radius";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  box-shadow: none;
`;

export const Title = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

export const ButtonMedia = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  border: 1px solid ${pallete.base.translucentBlack};
  color: ${pallete.base.blackAlpha};
  border-radius: 480px;
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-size: 0.75rem;
`;

export const CardBody = styled.div`
  margin-top: 1rem;
  display: flex;
`;

export const BoxGraph = styled.div`
  width: 100%;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-basis: 400px; */
  margin-right: 1rem;
`;

export const LegendItem = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;

  & + & {
    margin-top: 1.5rem;
  }
`;

export const Labels = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelsItem = styled.span`
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: ${pallete.base.black};
  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  &:first-of-type:before {
    background-color: #00a7f0;
  }

  &:nth-child(2):before {
    background-color: #f5910d;
  }

  & + & {
    margin-left: 1rem;
  }
`;
