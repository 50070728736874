import React from "react";
import {
  MetricCard,
  Progress,
  ProgressBar,
  Content,
  Title,
  Value,
  Qauntity,
  Percent,
  Container,
} from "./styles";

const PaymentMetricsEvent = ({ data, hideInfos = false }) => {
  return (
    <Container>
      {data.map((metric) => (
        <MetricCard key={metric.title}>
          <Progress percent={metric.percent}>
            <ProgressBar variant="determinate" value={metric.percent} />
          </Progress>

          <Content>
            <Title>{metric.title}</Title>
            <Value hideInfos={hideInfos}>
              {metric.value}
              <Qauntity hideInfos={hideInfos}>{metric.qauntity ?? 0}</Qauntity>
            </Value>
          </Content>
          <Percent>{metric.percent ?? 0}%</Percent>
        </MetricCard>
      ))}
    </Container>
  );
};

export default PaymentMetricsEvent;
