import React, { useCallback, useMemo, useState } from "react";
import { LinkPage, ScrollerHorizontal, Title } from "./styles";
import { ArrowRight } from "TGComponents/icons/ArrowRight";
import { CardProduct } from "..";
import { Box, Card } from "@mui/material";
import { TGSelectInput } from "TGComponents/global";
import CardProductOrEventSkeleton from "./CardProductOrEventSkeleton";

const CardMyEvents = ({ data, onSelectChange, loading }) => {
  const [value, setValue] = useState("");
  const eventListMemo = useMemo(() => {
    return [
      {
        label: "Encerrados",
        value: "encerrados",
      },
      {
        label: "Em andamento",
        value: "em_andamento",
      },
      {
        label: "Próximos eventos",
        value: "proximos_eventos",
      },
    ];
  }, []);

  const handleChange = useCallback(
    async (event) => {
      !!onSelectChange && onSelectChange(event.target.value);
      setValue(event.target.value);
    },
    [onSelectChange]
  );

  return (
    <>
      {loading && <CardProductOrEventSkeleton title={"Meus eventos"} />}

      {!loading && (
        <Card elevation={0} sx={{ borderRadius: "16px", marginTop: "20px" }}>
          <Box px={["0px", "0px", "30px"]} py={["0px", "0px", "30px"]}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ marginBottom: "32px" }}
            >
              <Title>Meus eventos</Title>
              <Box flexBasis={"160px"}>
                <TGSelectInput
                  placeholder={"Próximos eventos"}
                  values={eventListMemo}
                  onChange={handleChange}
                  variant="secondary"
                  value={value}
                />
              </Box>
            </Box>

            <ScrollerHorizontal>
              {data?.length > 0 &&
                data.map((item) => (
                  <CardProduct
                    key={item.id}
                    image={item.image || "/images/login/LoginLogoMobile.png"}
                    title={item.title}
                    category={item.categpry || "Não informada"}
                  />
                ))}
            </ScrollerHorizontal>

            <LinkPage to={"/admin/eventos"}>
              Ver página de eventos
              <ArrowRight color="#00a7f0" />
            </LinkPage>
          </Box>
        </Card>
      )}
    </>
  );
};

export default CardMyEvents;
