import styled from "styled-components";
import { Card as CardMUI } from "@mui/material";
import pallete from "TGComponents/theme/pallete";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.neutral[60]};
  box-shadow: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.h4`
  color: #222222;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;

  button {
    font-size: 0.875rem;
    background: none;
    border: 0;
    font-weight: 500;
    color: rgba(34, 34, 34, 0.56);
  }
`;
