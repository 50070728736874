import React from "react";
import { Box, Skeleton } from "@mui/material";

const ItemIconListSkeleton = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={"40px 1fr"}
      alignItems={"center"}
      gap={"10px"}
      mt={"16px"}
    >
      <Skeleton variant="circular" width={40} height={40} />

      <Skeleton variant="rounded" width="100%" height={25} />
    </Box>
  );
};

export default ItemIconListSkeleton;
