import { useMemo } from "react";
import { parseBrazilianNumber } from "lib/helpers/format";
import { formatNumberToK } from "lib/helpers/numbers";

export function fillArrayToSize(arr, size) {
  if (arr.length < size) {
    return arr.concat(new Array(size - arr.length).fill(""));
  }
  return arr;
}

const useGraphBestSellers = (grafico_mais_vendidos, hideInfos) => {
  return useMemo(() => {
    if (!grafico_mais_vendidos)
      return {
        options: {},
        data: {
          labels: [],
          datasets: [],
        },
      };

    const graphBestSellersResult = grafico_mais_vendidos;

    const pEvents = graphBestSellersResult?.filter(
      (event) => event.evento_tipo === "P"
    );
    const eEvents = graphBestSellersResult?.filter(
      (event) => event.evento_tipo === "E"
    );

    const maxLength = Math.max(pEvents.length, eEvents.length);

    const alternateData = Array.from({ length: maxLength * 2 })
      .map((_, index) => {
        if (index % 2 === 0) {
          return pEvents[Math.floor(index / 2)] || null;
        } else {
          return eEvents[Math.floor(index / 2)] || null;
        }
      })
      .filter((item) => item !== null);

    const mappedLabels = alternateData?.map((graph) =>
      graph.evento_tipo === "P" ? "Produto" : "Evento"
    );

    const labels = [...new Set(mappedLabels)];

    const mappedLabelsGraph = graphBestSellersResult?.map(
      (graph) => graph.evento_nome
    );

    const labelsGraph = fillArrayToSize(mappedLabelsGraph, 4);

    const colors = graphBestSellersResult?.map((graph) =>
      graph.evento_tipo === "P" ? "#F5AA2A" : "#53A462"
    );

    const datasets = graphBestSellersResult?.map((graph, i) => ({
      id: parseBrazilianNumber(graph.venda_acumulada),
      nested: {
        value: Number(graph.quantidade),
      },
    }));

    return {
      options: {
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return !hideInfos ? `R$ ${formatNumberToK(value)}` : "•••••••";
              },
            },
          },
        },
      },
      legend: labelsGraph,
      labels,
      data: {
        labels: labelsGraph,
        datasets: [
          {
            axis: "y",
            data: datasets,
            fill: false,
            borderColor: colors,
            backgroundColor: colors,
            borderRadius: 4,
            tension: 0.1,
          },
        ],
      },
    };
  }, [grafico_mais_vendidos, hideInfos]);
};

export default useGraphBestSellers;
