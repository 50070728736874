import React from "react";
import { Box, Skeleton } from "@mui/material";

const ActionsListSkeleton = () => {
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Skeleton variant="rectagunlar" width={75} height={75} />
      <Skeleton
        variant="rectagunlar"
        width={85}
        height={20}
        sx={{ marginTop: "10px" }}
      />
    </Box>
  );
};

export default ActionsListSkeleton;
