import { Box, Typography, Skeleton, Stack } from '@mui/material';
import { InputText } from './Inputs';
import warningIcon from 'assets/svgs/global/warningIcon.svg';
import CurrencyInput from 'react-currency-input-field';

import palette from 'TGComponents/theme/pallete';

export default function TGInput({
  name,
  title,
  label,
  placeholder,
  fontWeight,
  fontSize = '14px',
  textHelper,
  type = 'text',
  onChange,
  onBlur,
  value,
  defaultValue,
  required,
  warning,
  errorMessage,
  maskChar,
  disabled,
  mask,
  height,
  loading = false,
  id,
  maskPlaceholder,
  maxLength,
  convertTo,
  labelFontWeight,
  rightComponent,
  showSquareCustom,
  squareText,
  borderRadius,
  requiredIcon,
  tooltipText,
  tooltipIcon
}) {
  const handleOnChange = (e) => {
    let newValue = e.target.value;
    if (convertTo === 'integer') {
      newValue = parseInt(newValue);
      if (isNaN(newValue)) {
        newValue = '';
      }
    } else if (convertTo === 'decimal') {
      // Substitua vírgula por ponto
      newValue = newValue.replace(',', '.');
      newValue = parseFloat(newValue).toFixed(2);
      if (isNaN(newValue)) {
        newValue = '';
      }
    }
    onChange({ target: { value: newValue, name } });
  };
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      {title || label ? (
        <Typography
          variant="label-s"
          fontWeight={labelFontWeight}
          color={disabled ? 'rgba(34, 34, 34, 0.24)' : 'neutral.30'}
        >
          {title || label}{' '}
          {requiredIcon && (
            <strong style={{ color: palette.red[600] }}>*</strong>
          )}
        </Typography>
      ) : null}
      {loading ? (
        <Skeleton height="45px" />
      ) : (
        <Stack gap="10px" direction={['column', 'column', 'row']}>
          <Box
            display={showSquareCustom && 'flex'}
            position={'relative'}
            width="100%"
          >
            {showSquareCustom && (
              <Box
                height={height}
                bgcolor={palette.gray[100]}
                width="max-content"
                minWidth="max-content"
                padding="0 12px"
                border={`1px solid ${warning ? '#ff5858' : '#EEEEEE'}`}	
                borderRadius={'8px'}
                sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography
                  color={palette.base['blackAlpha']}
                  fontSize={'14px'}
                  fontWeight={'400'}
                >
                  {squareText}
                </Typography>
              </Box>
            )}

            <InputText
              maskChar={maskChar}
              defaultValue={defaultValue}
              name={name}
              placeholder={placeholder}
              type={type}
              onChange={handleOnChange}
              onBlur={onBlur}
              value={value}
              required={required}
              warning={warning}
              disabled={disabled}
              mask={mask}
              maskPlaceholder={maskPlaceholder}
              height={height}
              id={id}
              maxLength={maxLength}
              borderRadius={borderRadius}
              tooltipText={tooltipText}
              tooltipIcon={tooltipIcon}
            />

            {warning ? (
              <img
                src={warningIcon}
                height="16px"
                width={'16px'}
                alt="warningIconTextArea"
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '10px',
                }}
              />
            ) : null}
          </Box>

          {rightComponent}
        </Stack>
      )}

      {(errorMessage || textHelper) && (
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box
            flexDirection="column"
            display={'flex'}
            justifyContent={'space-between'}
          >
            {errorMessage ? (
              <Typography color="red.600" variant="body-s">
                {errorMessage}
              </Typography>
            ) : (
              <>
                {textHelper && (
                  <Typography
                    variant="body-s"
                    color={disabled ? 'rgba(34, 34, 34, 0.24)' : 'neutral.40'}
                  >
                    {textHelper}
                  </Typography>
                )}
              </>
            )}
          </Box>
          {maxLength && (
            <Typography
              variant="body-s"
              color="rgba(34, 34, 34, 0.56)"
              ml="auto"
            >
              <Typography
                display={'inline'}
                color="neutral.20"
                lineHeight={'15px'}
                fontSize={'10px'}
                fontWeight={'400'}
              >
                {value ? value?.length : 0}/
              </Typography>
              {maxLength}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export function TGInputMoney({ title, placeholder, textHelper, onChange }) {
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      <Typography color="#555555" fontSize={'12px'} lineHeight={'18px'}>
        {title}
      </Typography>
      <Box width={'100%'} display={'flex'} gap={'4px'}>
        <Box
          bgcolor={'#00B2FF'}
          width={'49px'}
          borderRadius={'8px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography color="#ffffff" fontSize={'14px'} fontWeight={'400'}>
            R$
          </Typography>
        </Box>
        <InputText
          placeholder={placeholder}
          type="number"
          min="0.00"
          max="10000.00"
          step="0.01"
          onChange={onChange}
        />
      </Box>
      {textHelper && (
        <Typography
          color="#999999"
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {textHelper}
        </Typography>
      )}
    </Box>
  );
}

export function TGInputPorcentagem({
  title,
  placeholder,
  textHelper,
  onChange,
  onBlur,
  value,
  showSquare = true,
  warning,
  errorMessage,
  loading,
  name,
  disabled,
}) {
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      <Typography color="#555555" fontSize={'12px'} lineHeight={'18px'}>
        {title}
      </Typography>
      {loading ? (
        <Skeleton height="45px" />
      ) : (
        <Box width={'100%'} display={'flex'} gap={'4px'}>
          {showSquare && (
            <Box
              bgcolor={'#00B2FF'}
              width={'55px'}
              borderRadius={'8px'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography color="#ffffff" fontSize={'14px'} fontWeight={'400'}>
                %
              </Typography>
            </Box>
          )}

          <Box position="relative" width="100%">
            <InputText
              warning={warning}
              name={name}
              placeholder={placeholder}
              mask="99,99%"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              height="45px"
            />

            {warning ? (
              <img
                src={warningIcon}
                height="16px"
                width={'16px'}
                alt="warningIconTextArea"
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '10px',
                }}
              />
            ) : null}
          </Box>
        </Box>
      )}

      {errorMessage ? (
        <Typography color="red.600" variant="body-s">
          {errorMessage}
        </Typography>
      ) : (
        <>
          {textHelper && (
            <Typography
              variant="body-s"
              color={disabled ? 'rgba(34, 34, 34, 0.24)' : 'neutral.40'}
            >
              {textHelper}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}

export function TGCurrencyInput({
  title,
  textHelper,
  onChange,
  value,
  defaultValue,
  onBlur,
  inputFontSize = '14px',
  warning,
  inputHeight = '49px',
  inputFontWeight = '400',
  inputPadding,
  prefix = 'R$ ',
  showSquare,
  placeholder = 'R$ 0,00',
  errorMessage,
  disabled,
  name = 'valor_aproximado',
  loading,
  zIndex = '99',
}) {
  return (
    <Box width={'100%'} display={'flex'} gap={'4px'} flexDirection={'column'}>
      {title && (
        <Typography color="#555555" fontSize={'12px'} lineHeight={'18px'}>
          {title}
        </Typography>
      )}
      <Box
        width={'100%'}
        display={'flex'}
        position="relative"
        alignItems="center"
      >
        <Box display={'flex'} position="relative" width={'100%'}>
          {loading ? (
            <Skeleton height="45px" />
          ) : (
            <>
              {showSquare && (
                <Box
                  bgcolor={'#00B2FF'}
                  width="46px"
                  borderRadius={'8px 0 0px 8px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginRight="-48px"
                  zIndex={zIndex}
                >
                  <Typography
                    color="#ffffff"
                    fontSize={'14px'}
                    fontWeight={'400'}
                  >
                    R$
                  </Typography>
                </Box>
              )}
              <CurrencyInput
                autoFocus={false}
                style={{
                  textIndent: showSquare ? '49px' : '',
                  fontSize: inputFontSize,
                  padding: '14px',
                  backgroundColor: '#ffffff',
                  borderRadius: '8px',
                  border: `1px solid ${warning ? '#ff5858' : '#EEEEEE'}`,
                  width: '100%',
                  height: inputHeight,
                  fontWeight: inputFontWeight,
                }}
                id={name}
                prefix={prefix}
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                decimalsLimit={2}
                decimalSeparator=","
                groupSeparator="."
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                onValueChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
              />
            </>
          )}

          {warning ? (
            <img
              src={warningIcon}
              height="16px"
              width={'16px'}
              alt="warningIconTextArea"
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '10px',
              }}
            />
          ) : null}
        </Box>
      </Box>
      {errorMessage ? (
        <Typography color="red.600" variant="body-s">
          {errorMessage}
        </Typography>
      ) : (
        <>
          {textHelper && (
            <Typography
              variant="body-s"
              color={disabled ? 'rgba(34, 34, 34, 0.24)' : 'neutral.40'}
            >
              {textHelper}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
