import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { Card as CardMUI } from "@mui/material";
import { radius } from "TGComponents/theme/radius";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  box-shadow: none;
`;

export const Title = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

export const ButtonMedia = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  border: 1px solid ${pallete.base.translucentBlack};
  color: ${pallete.base.blackAlpha};
  border-radius: 480px;
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-size: 0.75rem;
`;

export const CardBody = styled.div`
  margin-top: 1rem;
`;

export const BoxGraph = styled.div`
  width: 100%;
  max-height: 218px;

  canvas {
    height: 100% !important;
  }
`;

export const LegendSkeleton = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 30px;
`;
