import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { store } from 'store';
import appConfig from 'config';

export const useGetData = (
  url,
  customErrorMessage,
  params = {},
  apiV1,
  uuidProdutora = true,
  fetchOnMount = true,
  defaultLoading = true,
  customUuidProdutora
) => {
  const { user } = store.getState();
  const [data, setData] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState(null);
  const [lastRequestDate, setLastRequestDate] = useState(
    localStorage.getItem('lastRequestDate') || null
  );

  const fetchData = useCallback(async () => {
    const source = axios.CancelToken.source();
    try {
      setLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.access_token}`,
      };
      if (user.roles[0]?.uuid_produtora && uuidProdutora) {
        headers.uuidProdutora = customUuidProdutora
          ? customUuidProdutora
          : user.roles[0].uuid_produtora;
      }
      const response = await axios.get(
        `${
          apiV1 === true
            ? appConfig[appConfig.env].api
            : appConfig[appConfig.env].apiv2
        }/${url}`,
        {
          headers,
          params,
          cancelToken: source.token,
        }
      );
      setData(response.data);
      setResponse(response);
      const currentDate = new Date().toISOString();
      localStorage.setItem('lastRequestDate', currentDate);
      setLastRequestDate(currentDate);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setError(error);
        // handleFetchError(error, customErrorMessage);
      }
    } finally {
      setLoading(false);
    }

    return () => source.cancel(); // This line is used to cancel the request
  }, [
    url,
    user.access_token,
    user.roles,
    customErrorMessage,
    JSON.stringify(params),
    customUuidProdutora,
  ]);

  useEffect(() => {
    if (fetchOnMount === true) {
      fetchData();
    }
  }, [fetchData, fetchOnMount]); // Add fetchOnMount to the dependency array

  return {
    data,
    response,
    loading,
    error,
    fetchData,
    lastRequestDate,
    setLastRequestDate,
    setData,
  };
};
