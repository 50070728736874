import { Box, CardContent } from "@mui/material";
import { DoughnutChart } from "TGComponents/global/charts/DoughnutChart";
import React from "react";
import {
  Card,
  BoxGraph,
  BoxLegend,
  CardBody,
  Legend,
  LegendItem,
  LegendLabel,
  LegendTotal,
  Title,
  Total,
  LegendInfo,
} from "./styles";

const CardPaymentsForms = ({
  legend,
  options,
  data,
  total,
  hideInfos = false,
}) => {
  return (
    <>
      <Card>
        <CardContent>
          <Title>Formas de Pagamento</Title>
          {data.datasets.length < 1 && (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              color={"#2222228F"}
            >
              Dados insuficientes para gerar o relatório
            </Box>
          )}
          <CardBody>
            {data.datasets.length > 0 && (
              <BoxGraph>
                <DoughnutChart {...{ options, data }} />
              </BoxGraph>
            )}
            <BoxLegend>
              <Total hideInfos={hideInfos}>{total}</Total>
              <Legend>
                {legend?.length > 0 &&
                  legend.map((leg) => (
                    <LegendItem key={leg.id}>
                      <LegendLabel>
                        <LegendInfo hideInfos={hideInfos}>
                          <span>{leg.label}</span>
                          <span>{leg.quantity}</span>
                        </LegendInfo>
                        <LegendTotal>{leg.percent}%</LegendTotal>
                      </LegendLabel>
                    </LegendItem>
                  ))}
              </Legend>
            </BoxLegend>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardPaymentsForms;
