import React, { memo, useState } from "react";
import { Box, Tabs as TabsMUI } from "@mui/material";

import { Tab } from "./styles";
import { BorderBottom } from "@mui/icons-material";

const Tabs = ({ tabs, defaultActive = 0, onChange, children }) => {
  const [tabActive, setTabActive] = useState(defaultActive);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ py: "2rem" }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const handleChange = (_, newValue) => {
    !!onChange && onChange(newValue);
    setTabActive(newValue);
  };

  return (
    <Box width={"100%"}>
      <Box>
        <TabsMUI
          value={tabActive}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-flexContainer": { borderBottom: "1px solid #22222214" },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab key={tab.label} label={tab.label} {...a11yProps(index)} />
          ))}
        </TabsMUI>
        {tabs.map((tab, index) => (
          <CustomTabPanel key={index} value={tabActive} index={index}>
            {tab.content}
          </CustomTabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default memo(Tabs);
