import { useMemo } from "react";

const useFreeAndPaid = (relatorios, hideInfos) => {
  return useMemo(() => {
    if (!relatorios)
      return {
        options: {},
        data: {
          labels: [],
          datasets: [],
        },
      };

    const labels = ["Ingressos pagos", "Ingressos gratuitos"];

    const totalPagantes =
      relatorios.ingressos_gratuitos + relatorios.ingressos_pagantes;

    const totalPercentagePagantes =
      (relatorios.ingressos_pagantes / totalPagantes) * 100;

    const totalPercentageGratuitos =
      (relatorios.ingressos_gratuitos / totalPagantes) * 100;

    return {
      options: {
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value) {
                return !hideInfos ? value : "•••••••";
              },
            },
          },
        },
      },
      legend: labels,
      data: {
        labels: labels,
        datasets: [
          {
            data: [
              {
                id: totalPercentagePagantes,
                nested: { value: relatorios.ingressos_pagantes },
              },
              {
                id: totalPercentageGratuitos,
                nested: { value: relatorios.ingressos_gratuitos },
              },
            ],
            fill: false,
            borderColor: ["#00B2FF", "#F5AA2A"],
            backgroundColor: ["#00B2FF", "#F5AA2A"],
            borderRadius: 4,
            tension: 0.1,
            categoryPercentage: 0.5,
          },
        ],
      },
    };
  }, [relatorios, hideInfos]);
};

export default useFreeAndPaid;
