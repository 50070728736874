import dashboardActionTypes from "../actionTypes/dashboard";

export const get = (endpoint, criteria) => {
  return {
    type: dashboardActionTypes.GET,
    payload: { endpoint, criteria },
  };
};

export const getCards = (endpoint, type, criteria) => {
  return {
    type: dashboardActionTypes.GET_CARDS,
    payload: { endpoint, type, criteria },
  };
};

export const setResults = (results) => ({
  type: dashboardActionTypes.SET_RESULTS,
  payload: { results },
});

export const setResultsCards = (results) => ({
  type: dashboardActionTypes.SET_RESULTS_CARDS,
  payload: { results },
});

export const setLoadingType = (type) => ({
  type: dashboardActionTypes.SET_LOADING_TYPE,
  payload: { type },
});

export const setLoading = (flag) => ({
  type: dashboardActionTypes.SET_LOADING,
  payload: { flag },
});

export const setLoadingInitial = (flag) => ({
  type: dashboardActionTypes.SET_LOADING_INITIAL,
  payload: { flag },
});

export const setLoadingCards = (flag) => ({
  type: dashboardActionTypes.SET_LOADING_CARDS,
  payload: { flag },
});

export const clear = () => ({
  type: dashboardActionTypes.CLEAR,
});
