export const Eye = ({ size = 24, color = "#222222" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_955_30193)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.7495 9.32002C11.2665 9.32002 10.0695 10.517 10.0695 12C10.0695 13.4829 11.2666 14.68 12.7495 14.68C14.2324 14.68 15.4295 13.4829 15.4295 12C15.4295 10.517 14.2324 9.32002 12.7495 9.32002ZM8.26953 12C8.26953 9.52292 10.2724 7.52002 12.7495 7.52002C15.2265 7.52002 17.2295 9.52291 17.2295 12C17.2295 14.477 15.2265 16.48 12.7495 16.48C10.2724 16.48 8.26953 14.477 8.26953 12Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.77399 8.77865C5.21104 4.81305 8.79554 2.3999 12.7492 2.3999C16.7031 2.3999 20.2878 4.8133 22.7248 8.77926C23.2949 9.70443 23.5492 10.8807 23.5492 11.9999C23.5492 13.1193 23.2948 14.2959 22.7244 15.2212C20.2874 19.1868 16.7029 21.5999 12.7492 21.5999C8.79533 21.5999 5.21067 19.1865 2.77361 15.2205C2.20358 14.2954 1.94922 13.1191 1.94922 11.9999C1.94922 10.8805 2.20369 9.70391 2.77399 8.77865ZM12.7492 4.28328C9.5671 4.28328 6.50095 6.22257 4.30872 9.79011L4.30797 9.79134C3.96885 10.3413 3.76859 11.142 3.76859 11.9999C3.76859 12.8578 3.96885 13.6585 4.30796 14.2085L4.30872 14.2097C6.50095 17.7772 9.5671 19.7165 12.7492 19.7165C15.9313 19.7165 18.9975 17.7772 21.1897 14.2097L21.1905 14.2085C21.5296 13.6585 21.7299 12.8578 21.7299 11.9999C21.7299 11.142 21.5296 10.3413 21.1905 9.79134L21.1897 9.79011C18.9975 6.22257 15.9313 4.28328 12.7492 4.28328Z"
          fill={color ?? "currentColor"}
          fill-opacity="0.56"
        />
      </g>
      <defs>
        <clipPath id="clip0_955_30193">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
