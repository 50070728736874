import React from "react";

import { Box, CardContent, Skeleton } from "@mui/material";
import { helpIcon } from "assets/svgs";

import { Card } from "./styles";
import { Eye } from "TGComponents/icons";

const CardToggleInfoSkeleton = ({ title, bgColor }) => {
  return (
    <Card bgColor={bgColor}>
      <CardContent>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ mb: "16px" }}
        >
          {title}
          <Box>
            <Eye />

            <img
              src={helpIcon}
              alt="icon-tooltip"
              style={{ cursor: "pointer", marginLeft: "0.5rem" }}
            />
          </Box>
        </Box>

        <Skeleton
          variant="rounded"
          width="100%"
          height={20}
          sx={{ maxWidth: "180px", mb: "16px" }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height={15}
          sx={{ maxWidth: "160px" }}
        />
      </CardContent>
    </Card>
  );
};

export default CardToggleInfoSkeleton;
