const calculatePercentageOfTotal = (value, total) => {
  const formatValue = Number(value);
  const formatTotal = Number(total);
  const percent = (formatValue / formatTotal) * 100;
  return Math.round(percent);
};

const formatNumberToK = (num, decimals = 1) => {
  if (num >= 1000) {
    return (
      (num / 1000)
        .toFixed(decimals)
        .replace(new RegExp(`\\.0{1,${decimals}}$`), "") + "k"
    );
  }
  return num.toString();
};

function getMediaDataGraph(initialData, lengthOfDataChunks) {
  const numOfChunks = Math.ceil(initialData.length / lengthOfDataChunks);

  const dataChunks = Array.from({ length: numOfChunks }, (_, i) =>
    initialData.slice(i * lengthOfDataChunks, (i + 1) * lengthOfDataChunks)
  );

  const averagedChunks = dataChunks.map((chunk) => {
    const chunkSum = chunk.reduce((sum, value) => sum + value, 0);
    const chunkAverage = chunkSum / chunk.length;
    return chunk.map(() => chunkAverage);
  });

  return averagedChunks.flat();
}

export { calculatePercentageOfTotal, formatNumberToK, getMediaDataGraph };
