import { WrapperScroller } from "TGComponents/global/wrapper/Scroller";
import styled from "styled-components";

export const Container = styled(WrapperScroller)`
  display: grid;
  grid-template-columns: repeat(4, 250px);
  align-items: center;
  width: 100%;
  gap: 10px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, auto));
  }
`;
