import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/system";

import "./dashboard.scss";
import { Card, CircularProgress, Fade } from "@mui/material";
import { Subtitle } from "TGComponents/global";
import {
  CardActiveProducts,
  CardMyEvents,
  DashboadSkeleton,
  EmptyStateAllSales,
} from "./components";
import { TabAllSales, TabEvents, TabProducts } from "./components/Tabs";
import {
  BoxCalendar,
  ButtonShowAndHideInfos,
  ButtonUpdatePage,
  Title,
} from "./styles";
import { TGSelectInput } from "TGComponents/global";
import { useGetData } from "hooks/getData";
import { Tabs } from "TGComponents/global";
import useLocalStorage from "hooks/useLocalStorage";
import CalendarPicker from "TAGComponents/CalendarPicker";
import { TGShow } from "TGComponents/global";
import moment from "moment";
import { useOutsideClick } from "lib/helpers/click";
import { TGSideBarRight } from "TGComponents/global";
import { Refresh, Calendar, EyeSlash, Eye } from "TGComponents/icons";
import * as searchActions from "actions/searchActions";

const calculateTimeDifference = (date) => {
  if (!date) return "";
  const now = new Date();
  const past = new Date(date);
  const diffInMinutes = Math.floor((now - past) / 1000 / 60);

  if (diffInMinutes < 1) return "Atualizado agora";
  if (diffInMinutes === 1) return "Atualizado há 1 minuto";
  if (diffInMinutes < 60) return `Atualizado há ${diffInMinutes} minutos`;

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours === 1) return "Atualizado há 1 hora";
  if (diffInHours < 24) return `Atualizado há ${diffInHours} horas`;

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays === 1) return "Atualizado há 1 dia";
  return `Atualizado há ${diffInDays} dias`;
};

export const buildApiUrl = (basePath, tabType, params) => {
  const query = new URLSearchParams(params).toString();
  return `${basePath}/${tabType}/list?${query}`;
};

export const useFetchChange = ({ tabType, status }) => {
  const tab = tabType === "P" ? "product" : "event";
  const apiUrl = buildApiUrl("api/v2", tab, {
    page: 1,
    perPage: 10,
    orderBy: status ? "cadastro,desc" : "inicio,asc",
    ...(status && { status }),
  });

  const getData = useGetData(
    apiUrl,
    "Error fetching data",
    {},
    false,
    true,
    false,
    false
  );

  return getData;
};

const useFetchDataCards = (params) => {
  return useGetData(
    `api/v2/dashboard/cards`,
    "Error fetching data cards",
    params
  );
};

const useFetchGetBalance = () => {
  return useGetData(
    `api/v2/dashboard/saldo`,
    "Error fetching data",
    {},
    false,
    true,
    false,
    false
  );
};

const PERIOD_LIST = [
  {
    label: "Hoje",
    value: "today",
  },
  {
    label: "Últimos 7 dias",
    value: "lastSevenDays",
  },
  {
    label: "Últimos 30 dias",
    value: "lastMonth",
  },
  {
    label: "Últimos 6 meses",
    value: "lastSixMonths",
  },
  {
    label: "Últimos 12 meses",
    value: "lastTwelveMonths",
  },
  {
    label: "Definir período",
    value: "definePeriod",
  },
];

const startDate = moment().subtract(30, "days").format("DD/MM/YYYY");
const endDate = moment(new Date()).format("DD/MM/YYYY");

const Dashboard = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.user,
  }));

  const [initialLoading, setInitialLoading] = useState(true);
  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const [tabType, setTabType] = useState(0);
  const [getDataParams, setGetDataParams] = useState({
    periodo: "personalizado",
    data_inicial: startDate,
    data_final: endDate,
    tipo: null,
    uuid_produto: null,
  });
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [eventStatus, setEventStatus] = useState(null);
  const [selectSearchResult, setSelectSearchResult] = useState(null);
  const [selectPeriod, setSelectPeriod] = useState(null);
  const [periodsList, setPeriodsList] = useState(PERIOD_LIST);
  const [period, setPeriod] = useState("lastMonth");

  const { data, loading, lastRequestDate, fetchData } =
    useFetchDataCards(getDataParams);

  const {
    data: dataBalance,
    fetchData: fetchDataBalance,
    loading: loadingBalance,
  } = useFetchGetBalance();

  const {
    data: dataCards,
    fetchData: fetchDataChange,
    loading: loadingChangeStatus,
  } = useFetchChange({
    tabType,
    status: eventStatus,
  });

  const fetchCards = useCallback(async () => {
    await fetchData();
  }, [fetchData]);

  const fetchBalance = useCallback(async () => {
    await fetchDataBalance();
  }, [fetchDataBalance]);

  useEffect(() => {
    if (!tabType) {
      fetchBalance();
    }
  }, [fetchBalance, tabType]);

  useEffect(() => {
    if (initialLoading && !loading) {
      setInitialLoading(false);
    }
  }, [initialLoading, loading]);

  useEffect(() => {
    if (selectSearchResult) {
      fetchCards();
    }
  }, [selectSearchResult, fetchCards]);

  const [timeDifference, setTimeDifference] = useState(
    calculateTimeDifference(lastRequestDate)
  );

  const [hideInfos, setHideInfos] = useLocalStorage(
    "@TICKET_AND_GO:HIDE_INFOS",
    false
  );

  useEffect(() => {
    document.title = "Dashboard - Ticket And Go";
  });

  useEffect(() => {
    async function loadFetchTab() {
      await fetchDataChange();
    }

    if (tabType) {
      loadFetchTab(tabType, eventStatus);
    }
  }, [tabType, eventStatus, fetchDataChange]);

  const cardMemo = useMemo(() => {
    if (!dataCards || !dataCards.data) return { data: [] };

    const cardResponse = dataCards.data;

    const selectCardResponse =
      tabType === "P" ? cardResponse.produtos : cardResponse.eventos;

    if (!selectCardResponse) return { data: [] };

    const mappedResponse = selectCardResponse.map((card) => ({
      id: card.uuid,
      title: card.nome,
      category: card.categoria?.nome,
      image: card.imagem,
    }));

    return {
      data: mappedResponse,
    };
  }, [dataCards, tabType]);

  const hasDateSelectedMemo = useMemo(() => {
    return selectPeriod?.startDate || selectPeriod?.endDate;
  }, [selectPeriod]);

  const emptyStateTypeMemo = useMemo(() => {
    if (data) return null;

    const { uuid_produto, data_final, data_inicial } = getDataParams;

    const contentBlank = !uuid_produto && data_final && data_inicial;
    const contentNotfound = uuid_produto || (data_final && data_inicial);
    return contentNotfound ? "notfound" : contentBlank ? "blank" : null;
  }, [data, getDataParams]);

  const loadingType = useMemo(() => {
    if (loading && getDataParams.uuid_produto) {
      return "loadingSearch";
    }
    return "default";
  }, [loading, getDataParams]);

  const handleChange = (newValue) => {
    const type = newValue === 0 ? null : newValue === 1 ? "P" : "E";
    setTabType(newValue);
    setSelectSearchResult(null);
    setGetDataParams((prevState) => ({
      ...prevState,
      tipo: type,
      uuid_produto: null,
    }));
    dispatch(searchActions.setSearchDashboard(""));
  };

  const handleChangeEvent = (event) => {
    const period = event.target.value;
    const isToday = period === "today";
    const currentDate = new Date();
    const formatCurrentDate = moment(currentDate).format("DD/MM/YYYY");

    const periodDate = {
      today: 0,
      lastSevenDays: 7,
      lastMonth: 30,
      lastSixMonths: 180,
      lastTwelveMonths: 365,
    };

    if (period === "definePeriod") {
      setVisibleCalendar(true);
    } else {
      const dateTime = periodDate[period];

      const formatDateInitial = moment(currentDate)
        .subtract(dateTime, "days")
        .format("DD/MM/YYYY");

      setGetDataParams((prevState) => ({
        ...prevState,
        periodo: isToday ? "hoje" : "personalizado",
        data_inicial: isToday ? null : formatDateInitial,
        data_final: isToday ? null : formatCurrentDate,
      }));
    }
    setPeriod(period);
    return;
  };

  const fetchDataSubsCallback = useCallback(async () => {
    try {
      setRefreshLoading(true);
      await fetchData();
      const currentDate = new Date().toISOString();
      setTimeDifference(calculateTimeDifference(currentDate));
    } catch (e) {
      console.log(e);
    } finally {
      setRefreshLoading(false);
    }
  }, [fetchData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(calculateTimeDifference(lastRequestDate));
    }, 60000);

    return () => clearInterval(interval);
  }, [lastRequestDate]);

  const handleChangeEventStatus = useCallback(
    async (status) => {
      const formatStatus = status !== "proximos_eventos" ? status : "";
      setEventStatus(formatStatus);
    },
    [setEventStatus]
  );

  const loadEventStatus = useCallback(
    async (status) => {
      await fetchDataChange(tabType, status);
    },
    [fetchDataChange, tabType]
  );

  const handleClickOutsideCalendar = () => {
    setVisibleCalendar(false);
  };

  const refDatePicker = useOutsideClick(handleClickOutsideCalendar);

  const handleChangePeriodDate = useCallback(
    (value, field) => {
      const formatDate = moment(value).format("DD/MM/YYYY");

      setSelectPeriod((prevState) => {
        const updatedPeriod = { ...prevState, [field]: formatDate };
        const isInvalidEndDate = updatedPeriod?.endDate === "Invalid date";

        const customPeriod = {
          label: `de ${updatedPeriod.startDate || ""} até ${isInvalidEndDate ? updatedPeriod.startDate : updatedPeriod.endDate || ""}`,
          value: "selectPeriod",
        };

        setPeriodsList((prevPeriods) => {
          const periodExists = prevPeriods.some(
            (period) => period.value === "selectPeriod"
          );

          if (periodExists) {
            return prevPeriods.map((period) =>
              period.value === "selectPeriod" ? customPeriod : period
            );
          } else {
            return [...prevPeriods, customPeriod];
          }
        });

        setPeriod("selectPeriod");

        if (value && field === "endDate" && !isInvalidEndDate) {
          setGetDataParams((prevState) => ({
            ...prevState,
            periodo: "personalizado",
            data_inicial: updatedPeriod.startDate,
            data_final: updatedPeriod.endDate,
          }));
        }

        return updatedPeriod;
      });
    },
    [setSelectPeriod, setPeriodsList, setPeriod]
  );

  useEffect(() => {
    if (eventStatus) {
      loadEventStatus(eventStatus);
    }
  }, [eventStatus, loadEventStatus]);

  const tabs = [
    {
      label: "Todas as vendas",
      content: (
        <TabAllSales
          {...data?.data}
          loading={loading}
          loadingBalance={loadingBalance}
          hideInfos={hideInfos}
          emptyStateType={emptyStateTypeMemo}
          selectPeriod={selectPeriod}
          period={period}
          saldo={dataBalance?.data}
        />
      ),
    },
    {
      label: "Produtos",
      content: (
        <TabProducts
          {...data?.data}
          loading={loadingChangeStatus || loading}
          hideInfos={hideInfos}
          emptyStateType={emptyStateTypeMemo}
          setGetDataParams={setGetDataParams}
          loadingType={loadingType}
          period={period}
        />
      ),
    },
    {
      label: "Eventos",
      content: (
        <TabEvents
          {...data?.data}
          loading={loadingChangeStatus || loading}
          hideInfos={hideInfos}
          emptyStateType={emptyStateTypeMemo}
          setGetDataParams={setGetDataParams}
          loadingType={loadingType}
          period={period}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ marginInline: "20px" }}>
        <Card elevation={0} sx={{ borderRadius: "16px" }}>
          <Box px={["0px", "0px", "40px"]} py={["0px", "0px", "40px"]}>
            <Box
              display={"flex"}
              flexDirection={["column", "column", "column", "row"]}
              alignItems={["center", "center", "center", "flex-start"]}
              justifyContent={"space-between"}
            >
              <Box width={["100%", "100%", "100%", "auto"]}>
                <Title>Boas vindas, {user.nome}</Title>
                <Box
                  display={["block", "block", "block", "none"]}
                  sx={{ my: "1rem" }}
                >
                  <TGSelectInput
                    values={periodsList}
                    onChange={handleChangeEvent}
                    variant="secondary"
                    value={period}
                    iconStart={<Calendar />}
                    dividerItemIndex={4}
                    arrowItemIndex={5}
                    onHideLastItem={hasDateSelectedMemo}
                  />
                </Box>
                <Subtitle
                  sx={{ marginBottom: "1rem" }}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  Veja um resumo de toda a sua operação.
                  <TGShow breakpoint="lg" display="below">
                    <ButtonShowAndHideInfos
                      type="button"
                      onClick={() => setHideInfos(!hideInfos)}
                    >
                      {!hideInfos ? <Eye /> : <EyeSlash />}
                    </ButtonShowAndHideInfos>
                  </TGShow>
                </Subtitle>
              </Box>

              <Box display={"flex"} alignItems={"center"}>
                <TGShow breakpoint="lg" display="above">
                  <ButtonShowAndHideInfos
                    type="button"
                    onClick={() => setHideInfos(!hideInfos)}
                  >
                    {!hideInfos ? <Eye /> : <EyeSlash />}
                  </ButtonShowAndHideInfos>
                </TGShow>

                <TGShow breakpoint="lg" display="above">
                  <Box minWidth={"225px"}>
                    <ButtonUpdatePage
                      type="button"
                      onClick={() => fetchDataSubsCallback()}
                    >
                      {refreshLoading && (
                        <Box
                          minWidth={"157px"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          <CircularProgress
                            sx={{
                              color: "#00A7F0",
                              marginRight: "8px",
                            }}
                            size={16}
                          />
                          Atualizando...
                        </Box>
                      )}
                      {!refreshLoading && (
                        <>
                          <Box mr={3}>
                            <Refresh />
                          </Box>
                          {timeDifference}
                        </>
                      )}
                    </ButtonUpdatePage>
                  </Box>
                </TGShow>

                <Box display={["none", "none", "none", "block"]}>
                  <TGSelectInput
                    values={periodsList}
                    onChange={handleChangeEvent}
                    variant="secondary"
                    value={period}
                    iconStart={<Calendar />}
                    dividerItemIndex={4}
                    arrowItemIndex={5}
                    onHideLastItem={hasDateSelectedMemo}
                  />
                </Box>

                {visibleCalendar && (
                  <Box
                    position={"relative"}
                    ref={refDatePicker}
                    sx={{
                      border: "1px solid red",
                    }}
                  >
                    <TGShow breakpoint="md" display="above">
                      <BoxCalendar>
                        <Fade visible={visibleCalendar} timeout={600}>
                          <CalendarPicker
                            disableFuture
                            disablePast={false}
                            startDateValue={selectPeriod?.startDate}
                            endDateValue={selectPeriod?.endDate}
                            hideTime
                            setStartDateValue={(value) =>
                              handleChangePeriodDate(value, "startDate")
                            }
                            setEndDateValue={(value) =>
                              handleChangePeriodDate(value, "endDate")
                            }
                          />
                        </Fade>
                      </BoxCalendar>
                    </TGShow>

                    <TGShow breakpoint="md" display="below">
                      <TGSideBarRight
                        onClose={() => setVisibleCalendar(false)}
                        isOpen={true}
                        title="Menu"
                        rowGap={0}
                      >
                        <CalendarPicker
                          disableFuture
                          disablePast={false}
                          startDateValue={selectPeriod?.startDate}
                          endDateValue={selectPeriod?.endDate}
                          hideTime
                          setStartDateValue={(value) =>
                            handleChangePeriodDate(value, "startDate")
                          }
                          setEndDateValue={(value) =>
                            handleChangePeriodDate(value, "endDate")
                          }
                        />
                      </TGSideBarRight>
                    </TGShow>
                  </Box>
                )}
              </Box>
            </Box>

            {initialLoading && loading && getDataParams.tipo === null && (
              <DashboadSkeleton />
            )}

            {!data && !selectPeriod && !loading && (
              <TGShow breakpoint="md" display="below">
                <EmptyStateAllSales />
              </TGShow>
            )}

            {!initialLoading && (
              <>
                <TGShow breakpoint="md" display="below">
                  <TabAllSales
                    {...data?.data}
                    {...dataBalance?.data}
                    loading={tabType === null && loading}
                    loadingBalance={loadingBalance}
                    hideInfos={hideInfos}
                    emptyStateType={emptyStateTypeMemo}
                    setSelectSearchResult={setSelectSearchResult}
                    selectSearchResult={selectSearchResult}
                    period={period}
                  />
                </TGShow>

                <TGShow breakpoint="md" display="above">
                  <Tabs
                    tabs={tabs}
                    defaultActive={tabType}
                    onChange={handleChange}
                  />
                </TGShow>
              </>
            )}
          </Box>
        </Card>

        {tabType === 1 && <CardActiveProducts {...cardMemo} />}
        {tabType === 2 && (
          <CardMyEvents
            {...cardMemo}
            onSelectChange={handleChangeEventStatus}
            loading={loadingChangeStatus}
          />
        )}
      </Box>
    </>
  );
};

export default memo(Dashboard);
