import React from "react";
import { Box, CardContent, Skeleton } from "@mui/material";
import {
  Card,
  ButtonMedia,
  BoxGraph,
  CardBody,
  Title,
  LegendSkeleton,
} from "./styles";
import { LineChart } from "TGComponents/global/charts/LineChart";
import { formatCurrency } from "lib/utils";

const CardEvolutionSalesSkeleton = ({ title }) => {
  const options = {
    scales: {
      y: {
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value) {
            return "R$ " + formatCurrency(value);
          },
        },
      },
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };
  const data = {
    labels: ["R$ 30.000", "R$ 60.000", "R$ 90.000", "R$ 120.000"],
    datasets: [
      {
        data: [0, 30000, 60000, 90000, 120000],
        fill: false,
        borderColor: "rgb(0, 178, 255)",
        tension: 0.1,
      },
    ],
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title>{title}</Title>
            <ButtonMedia type="button">Média</ButtonMedia>
          </Box>
          <CardBody>
            <Box>
              <BoxGraph>
                <LineChart {...{ options, data }} />
              </BoxGraph>
              <LegendSkeleton>
                {Array.from(Array(15).keys()).map((item) => (
                  <Skeleton
                    key={item}
                    variant="rounded"
                    width={27}
                    height={11}
                  />
                ))}
              </LegendSkeleton>
            </Box>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardEvolutionSalesSkeleton;
