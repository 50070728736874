import { getOrCreateTooltip } from "./getOrCreateTooltip";

const { formatToBrl } = require("lib/helpers/format");

const externalTooltipHandlerLineChart = (context) => {
  const { chart, tooltip } = context;

  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);
    const bodyLinesLimit = [bodyLines[0]];

    const tableHead = document.createElement("thead");

    const tableBody = document.createElement("div");
    tableBody.style.width = "190px";
    tableBody.style.paddingInline = "5px";
    tableBody.style.maxWidth = "100%";

    const formatedTotalValue = formatToBrl(
      tooltip.dataPoints[0].formattedValue.replaceAll(",", "")
    );

    const labelTooltip = tooltip.dataPoints[0].label;

    bodyLinesLimit.forEach((body, i) => {
      const wrapper = document.createElement("div");
      wrapper.style.display = "flex";
      wrapper.style.flexDirection = "column";

      const itemTitle = document.createElement("h3");
      itemTitle.innerHTML = "Vendas";
      itemTitle.style.fontWeight = "500";
      itemTitle.style.fontSize = "16px";

      const dateText = document.createElement("p");
      dateText.innerHTML = labelTooltip;
      dateText.style.paddingBottom = "8px";
      dateText.style.fontWeight = "400";
      dateText.style.fontSize = "12px";
      dateText.style.borderBottom = "1px solid #22222214";
      dateText.style.color = "#2222228F";
      dateText.style.marginBottom = "4px";

      const sellsText = document.createElement("p");
      sellsText.innerHTML = (tooltip.dataPoints[0].raw.id ?? 0) + " vendas";
      sellsText.style.fontWeight = "400";
      sellsText.style.fontSize = "12px";
      sellsText.style.color = "#2222228F";
      sellsText.style.marginBottom = "4px";

      const valuesTotalText = document.createElement("p");
      valuesTotalText.innerHTML = formatedTotalValue;
      valuesTotalText.style.fontWeight = "500";
      valuesTotalText.style.fontSize = "14px";
      valuesTotalText.style.color = "#2222228F";
      valuesTotalText.style.marginBottom = "4px";

      wrapper.appendChild(itemTitle);
      wrapper.appendChild(dateText);
      wrapper.appendChild(sellsText);
      wrapper.appendChild(valuesTotalText);
      tableBody.appendChild(wrapper);
    });

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

export { externalTooltipHandlerLineChart };
