import { combineReducers } from "redux";

import address from "./address";
import application from "./application";
import crud from "./crud";
import notification from "./notification";
import search from "./search";
import lists from "./lists";
import single from "./single";
import user from "./user";
import dashboard from "./dashboard";

// import { intlReducer } from 'react-intl-redux'

const createRootReducer = combineReducers({
  address,
  application,
  crud,
  notification,
  search,
  lists,
  single,
  user,
  dashboard,
});

export default createRootReducer;
