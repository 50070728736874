import styled, { css } from "styled-components";
import { Card as CardMUI } from "@mui/material";
import pallete from "TGComponents/theme/pallete";

const wrapperModifiersCard = {
  info: () => css`
    background-color: #f2f9fc;

    .MuiSkeleton-root {
      background-color: #bee9fa;
    }
  `,
  normal: () => css`
    background-color: rgba(250, 250, 250, 1);
  `,
};

export const Card = styled(CardMUI)`
  ${({ bgColor }) => css`
    border: 1px solid ${pallete.neutral[60]};
    box-shadow: none;
    height: 100%;
    ${wrapperModifiersCard[bgColor || "normal"]()}
  `}
`;
