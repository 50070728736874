import React from "react";
import { Box, Skeleton, Card } from "@mui/material";
import { CardProductSkeleton, ScrollerHorizontal, Title } from "./styles";

const CardProductOrEventSkeleton = ({ title }) => {
  return (
    <>
      <Card elevation={0} sx={{ borderRadius: "16px", marginTop: "20px" }}>
        <Box px={["0px", "0px", "30px"]} py={["0px", "0px", "30px"]}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ marginBottom: "32px" }}
          >
            <Title>{title}</Title>
          </Box>

          <ScrollerHorizontal>
            {Array.from(Array(4).keys()).map((item) => (
              <CardProductSkeleton key={item}>
                <Box display={"flex"}>
                  <Skeleton variant="rounded" width={100} height={100} />
                  <Box ml={4}>
                    <Skeleton
                      variant="rounded"
                      width={145}
                      height={14}
                      sx={{ marginBottom: "10px" }}
                    />
                    <Skeleton variant="rounded" width={80} height={14} />
                  </Box>
                </Box>
              </CardProductSkeleton>
            ))}
          </ScrollerHorizontal>
        </Box>
      </Card>
    </>
  );
};
export default CardProductOrEventSkeleton;
