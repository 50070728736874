export const Currency = ({ size = 24, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4031_7395)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.25 7.55C16.9127 7.55 17.45 8.08726 17.45 8.75V9.15H17.8548C20.2899 9.15 22.25 11.1176 22.25 13.55C22.25 14.2128 21.7127 14.75 21.05 14.75C20.3873 14.75 19.85 14.2128 19.85 13.55C19.85 12.4391 18.9604 11.55 17.8548 11.55H17.45V15.8835L19.5346 16.5746C20.1829 16.7938 20.9102 17.1292 21.4537 17.7908C22.0147 18.4738 22.25 19.3408 22.25 20.362C22.25 22.5648 20.4587 24.35 18.2578 24.35H17.45V24.75C17.45 25.4127 16.9127 25.95 16.25 25.95C15.5873 25.95 15.05 25.4127 15.05 24.75V24.35H14.6452C12.2101 24.35 10.25 22.3824 10.25 19.95C10.25 19.2872 10.7873 18.75 11.45 18.75C12.1127 18.75 12.65 19.2872 12.65 19.95C12.65 21.0609 13.5396 21.95 14.6452 21.95H15.05V17.6164L12.9654 16.9254C12.317 16.7061 11.5898 16.3708 11.0463 15.7091C10.4853 15.0262 10.25 14.1591 10.25 13.138C10.25 10.9352 12.0413 9.15 14.2422 9.15H15.05V8.75C15.05 8.08726 15.5873 7.55 16.25 7.55ZM14.2422 11.55H15.05V15.088L13.731 14.6507C13.2446 14.4859 13.0226 14.334 12.9008 14.1857C12.7961 14.0583 12.65 13.7923 12.65 13.138C12.65 12.2644 13.3631 11.55 14.2422 11.55ZM17.45 21.95V18.412L18.769 18.8492C19.2554 19.0141 19.4774 19.1659 19.5992 19.3143C19.7038 19.4416 19.85 19.7076 19.85 20.362C19.85 21.2356 19.1369 21.95 18.2578 21.95H17.45Z"
          fill={color ?? "currentColor"}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.25 2.75C8.51801 2.75 2.25 9.01801 2.25 16.75C2.25 24.4819 8.51801 30.75 16.25 30.75C23.9819 30.75 30.25 24.4819 30.25 16.75C30.25 9.01801 23.9819 2.75 16.25 2.75ZM4.65 16.75C4.65 10.3435 9.84349 5.15 16.25 5.15C22.6564 5.15 27.85 10.3435 27.85 16.75C27.85 23.1564 22.6564 28.35 16.25 28.35C9.8435 28.35 4.65 23.1564 4.65 16.75Z"
          fill={color ?? "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_4031_7395">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.25 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
