import { Card as CardMUI } from "@mui/material";
import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { radius } from "TGComponents/theme/radius";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.neutral[60]};
  box-shadow: none;
  border-radius: ${radius.lg};
  & + & {
    margin-top: 1.125rem;
  }
`;
