import React from "react";
import { CardToggleInfoSkeleton } from "TGComponents/global/skeletons";
import { CardsContainer, ReportsBox, ReportsContainer, Title } from "./styles";
import { CardPaymentsFormsSkeleton } from "TGComponents/global/skeletons";
import CardBestSellersSkeleton from "TGComponents/global/skeletons/layouts/CardBestSellersSkeleton/CardBestSellersSkeleton";
import { PaymentMetricsSkeleton } from "TGComponents/global/skeletons";
import { CardEvolutionSalesSkeleton } from "TGComponents/global/skeletons";
import { Box, Skeleton } from "@mui/material";

const TabProductsSkeleton = () => {
  const LEGEND_PAYMENT_FORMS = [
    {
      id: 1,
      percent: 40 || 0,
    },
    {
      id: 2,
      percent: 10 || 0,
    },
    {
      id: 3,
      percent: 30 || 0,
    },
    {
      id: 4,
      percent: 20 || 0,
    },
  ];

  const LEGEND_BEST_SELLETS = [
    {
      id: 1,
      label: "Introdução ao Community Management",
    },
    {
      id: 2,
      label: "Fazendinha de parelheiros",
    },
    {
      id: 3,
      label: "Introdução ao Community Management",
    },
    {
      id: 4,
      label: "Fazendinha de parelheiros - Brasília",
    },
  ];

  const PaymentMetricsData = [
    {
      title: "Conversão de boleto",
      percent: 62,
    },
    {
      title: "Reembolso",
      percent: 8,
    },
    {
      title: "Chargeback",
      percent: 9,
    },
  ];

  return (
    <>
      <Box width={"100%"}>
        <CardsContainer>
          <CardToggleInfoSkeleton title="Receita" />
          <CardToggleInfoSkeleton title="Ticket médio" />
          <CardToggleInfoSkeleton title="Vendas por afiliados" />
        </CardsContainer>

        <ReportsContainer>
          <Title>Relatórios</Title>

          <ReportsBox>
            <CardPaymentsFormsSkeleton
              title={"Formas de pagamento"}
              legends={LEGEND_PAYMENT_FORMS}
            />
            <Box display={["none", "none", "none", "block"]}>
              <CardBestSellersSkeleton
                title={"Mais vendidos"}
                legends={LEGEND_BEST_SELLETS}
              />
            </Box>
          </ReportsBox>

          <PaymentMetricsSkeleton data={PaymentMetricsData} />

          <Box display={["none", "none", "none", "block"]}>
            <CardEvolutionSalesSkeleton title={"Evolução das vendas"} />
          </Box>
        </ReportsContainer>
      </Box>
    </>
  );
};

export default TabProductsSkeleton;
