import { css, styled } from "@mui/material";
import pallete from "TGComponents/theme/pallete";
import { radius } from "TGComponents/theme/radius";

export const Container = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column-reverse;
    border-radius: ${radius.md};
    border: 1px solid ${pallete.base.translucentBlack};

    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const BoxLeft = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    @media (min-width: ${theme.breakpoints.values.md}px) {
      padding: 30px 40px;
    }
  `}
`;

export const Title = styled("div")`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${pallete.base.black};
  margin-bottom: 0.75rem;
`;

export const Description = styled("div")`
  color: ${pallete.base.blackAlpha};
  font-size: 0.875rem;
  margin-bottom: 2rem;
`;

export const ButtonGroups = styled("div")``;

export const BoxRight = styled("div")`
  ${({ theme }) => css`
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: ${radius.md};
      border-top-left-radius: ${radius.md};
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      img {
        border-top-right-radius: ${radius.md};
        border-bottom-right-radius: ${radius.md};
      }
    }
  `}
`;
