import React from "react";
import { Category, Container, Content, Title } from "./styles";

const CardProduct = ({ image, title, category, loading }) => {
  return (
    <Container>
      <img src={image} alt={title} />
      <Content>
        <Title>{title}</Title>
        <Category>{category}</Category>
      </Content>
    </Container>
  );
};

export default CardProduct;
