const dashboardTypes = {
  GET: "DASHBOARD_LIST/GET",
  GET_CARDS: "DASHBOARD_LIST/GET_CARDS",
  SET_RESULTS: "DASHBOARD/SET_RESULTS",
  SET_RESULTS_CARDS: "DASHBOARD/SET_RESULTS_CARDS",
  SET_PARAM: "DASHBOARD/SET_PARAM",
  SET_LOADING: "DASHBOARD/SET_LOADING",
  SET_LOADING_TYPE: "DASHBOARD/SET_LOADING_TYPE",
  SET_LOADING_INITIAL: "DASHBOARD/SET_LOADING_INITIAL",
  SET_LOADING_CARDS: "DASHBOARD/SET_LOADING_CARDS",
  CLEAR: "DASHBOARD/CLEAR",
};

export default dashboardTypes;
