import { takeEvery, all, takeLatest } from "redux-saga/effects";

import searchActionTypes from "./../actionTypes/search";
import addressActionTypes from "./../actionTypes/address";
import crudActionTypes from "./../actionTypes/crud";
import listsActionTypes from "./../actionTypes/lists";
import singleActionTypes from "./../actionTypes/single";
import dashboardActionTypes from "./../actionTypes/dashboard";

import * as addressSaga from "./address-saga";
import * as userSaga from "./user-saga";
import * as searchSaga from "./search-saga";
import * as listsSaga from "./lists-saga";
import * as singleSaga from "./single-saga";
import * as crudSaga from "./crud-saga";
import * as dashBoardSaga from "./dashboard-saga";

function* allSaga() {
  yield all([
    yield takeLatest("login", userSaga.login),
    yield takeLatest("recover", userSaga.recover),
    yield takeLatest(searchActionTypes.SEARCH, searchSaga.getSearch),
    yield takeEvery(listsActionTypes.GET_LISTS, listsSaga.getLists),
    yield takeEvery(singleActionTypes.GET_SINGLE, singleSaga.getSingle),
    yield takeLatest(singleActionTypes.DOWNLOAD, singleSaga.download),
    yield takeLatest(singleActionTypes.PUT_SINGLE, singleSaga.putSingle),
    yield takeLatest(singleActionTypes.POST_SINGLE, singleSaga.postSingle),
    yield takeLatest(singleActionTypes.DELETE_SINGLE, singleSaga.deleteSingle),
    yield takeLatest(crudActionTypes.GET, crudSaga.getSearch),
    yield takeLatest(crudActionTypes.INSERT, crudSaga.insert),
    yield takeLatest(crudActionTypes.UPDATE, crudSaga.update),
    yield takeLatest(crudActionTypes.DELETE, crudSaga.remove),
    yield takeLatest(addressActionTypes.GET_ADDRESS, addressSaga.getAddress),
    yield takeLatest(dashboardActionTypes.GET, dashBoardSaga.getList),
    yield takeLatest(
      dashboardActionTypes.GET_CARDS,
      dashBoardSaga.getCardProductOrEvent
    ),
  ]);
}

export default allSaga;
