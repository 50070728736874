import styled, { css } from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { Card as CardMUI } from "@mui/material";
import { radius } from "TGComponents/theme/radius";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  box-shadow: none;
`;

export const Title = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

export const ButtonMedia = styled.button`
  ${({ active }) =>
    !active
      ? css`
          background: none;
          border: 1px solid ${pallete.base.translucentBlack};
          color: ${pallete.base.blackAlpha};
        `
      : css`
          border: 1px solid #00a7f0;
          color: #00a7f0;
          background-color: #f2f9fc;
        `};
  cursor: pointer;
  border-radius: 480px;
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-size: 0.75rem;
`;

export const CardBody = styled.div`
  margin-top: 1rem;
`;

export const BoxGraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 280px;
  overflow: hidden;

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  @media (min-width: 1200px) {
    canvas {
      height: 208px !important;
      max-height: 100%;
    }
  }
`;
