import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { radius } from "TGComponents/theme/radius";

export const Container = styled.div`
  padding: 1.25rem;
  display: flex;
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  flex-basis: 300px;
  flex-shrink: 0;

  img {
    width: 100px;
    height: 100px;
    border-radius: ${radius.sm};
    object-fit: cover;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

export const Title = styled.p`
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  color: ${pallete.base.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
`;

export const Category = styled.span`
  color: ${pallete.base.blackAlpha};
  font-size: 0.875rem;
  font-weight: 500;
`;
