import { Box } from "@mui/material";
import React from "react";
import { Subtitle, Title } from "./styles";

const Notfound = ({ title, subtitle }) => {
  return (
    <Box minHeight={"35rem"} display="flex">
      <Box
        display="flex"
        flexDirection={"column"}
        justifyContent="center"
        alignItems="center"
        maxWidth={"520px"}
        width={"100%"}
        margin={"auto"}
      >
        <img src={"/images/emojis/ops.svg"} alt={title} />
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Box>
    </Box>
  );
};

export default Notfound;
