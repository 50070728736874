import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Autocomplete,
  Skeleton,
  CircularProgress,
} from "@mui/material";

import * as React from "react";
import warningIcon from "assets/svgs/global/warningIcon.svg";
import TGIcon from "./TGIcon";
import { ArrowDown } from "assets/svgs";
import { SearchIcon } from "TGComponents/icons/SearchIcon";

export default function TGSelectAuto({
  label,
  textHelper,
  values,
  buttonStyle,
  placeholder,
  onChange,
  onInputChange,
  warning,
  errorMessage,
  selectedValues,
  showCheckmarks = true,
  multiple = true,
  loading,
  disabled = false,
  variant,
  borderRadius,
  ...props
}) {
  return (
    <Box width={"100%"} display={"flex"} gap={"4px"} flexDirection={"column"}>
      {label && (
        <Typography color="#555555" fontSize={"12px"} lineHeight={"18px"}>
          {label}
        </Typography>
      )}

      <Box position="relative">
        {loading && !variant ? (
          <Skeleton height="45px" />
        ) : (
          <>
            {!variant && (
              <CustomSelect
                values={values}
                buttonStyle={buttonStyle}
                placeholder={placeholder}
                onChange={onChange}
                warning={warning}
                selectedValues={selectedValues}
                showCheckmarks={showCheckmarks}
                multiple={multiple}
                disabled={disabled}
                borderRadius={borderRadius}
              />
            )}

            {variant === "secondary" && (
              <CustomSelectSecondary
                placeholder={placeholder}
                loading={loading}
                onChange={onChange}
                {...props}
              />
            )}
          </>
        )}
        {warning ? (
          <img
            src={warningIcon}
            height="16px"
            width={"16px"}
            alt="warningIconTextArea"
            style={{ position: "absolute", top: "13px", right: "10px" }}
          />
        ) : null}
      </Box>
      {textHelper && (
        <Typography
          color="#999999"
          lineHeight={"15px"}
          fontSize={"10px"}
          fontWeight={"400"}
        >
          {textHelper}
        </Typography>
      )}
      {errorMessage && (
        <Typography color="#FF5858" lineHeight={"15px"} fontSize={"10px"}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}

export function CustomSelect({
  values = [],
  selectedValues = [],
  onChange,
  onInputChange,
  placeholder,
  multiple,
  disabled = false,
  disableCloseOnSelect,
  freeSolo = false,
  warning,
  onBlur,
  borderRadius
}) {
  const handleAutocompleteChange = (event, newValue) => {
    const value = multiple
      ? newValue.map((item) => item.value)
      : newValue?.value || "";

    onChange({
      target: {
        value,
      },
    });
  };

  return (
    <Autocomplete
      noOptionsText="Nada encontrado"
      multiple={multiple}
      options={values}
      getOptionLabel={(option) => option?.label}
      value={selectedValues}
      disabled={disabled}
      onChange={handleAutocompleteChange}
      disableCloseOnSelect={disableCloseOnSelect}
      onBlur={onBlur}
      onInputChange={onInputChange} // debounce
      freeSolo={freeSolo}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            minWidth: "66px !important",
            border: "0px solid transparent !important",
            "& .MuiInputBase-root fieldset": {
              border: "1px solid #EEEEEE !important",
              borderRadius: "8px solid !important",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px !important",
              color: "rgba(0, 0, 0, 0.79) !important",
            },
            "& .MuiInputBase-root": {
              width: "100%",
              minHeight: "48px !important",
              bgcolor: "#fff !important",
              borderRadius: borderRadius || "8px solid !important",
            },
            "& .MuiOutlinedInput-input": {
              width: "100%",
              bgcolor: "#fff !important",
              border: "0px solid transparent !important",
            },
          }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: <>{params.InputProps.startAdornment}</>,
            endAdornment: (
              <TGIcon
                style={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "12px",
                }}
                src={ArrowDown}
                width="18px"
                height="18px"
              />
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple && (
            <Checkbox
              key={option?.value + option?.label}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          )}
          {option.label}
        </li>
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
}

export function CustomSelectSecondary({
  placeholder,
  onChange,
  loading,
  value,
  ...props
}) {
  console.log("🚀 ~ value:", value);
  return (
    <Autocomplete
      {...props}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            minWidth: "66px !important",
            border: "0px solid transparent !important",
            "& .MuiInputBase-root fieldset": {
              border: "1px solid #22222214",
              borderRadius: "480px",
              backgroundColor: "#c1c1c114",
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px !important",
              color: "rgba(0, 0, 0, 0.79) !important",
            },
            "& .MuiInputBase-root": {
              width: "100%",
              minHeight: "48px !important",
              bgcolor: "#fff !important",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-input": {
              width: "100%",
              bgcolor: "#fff !important",
              border: "0px solid transparent !important",
            },
            "& .MuiIconButton-root": {
              display: "none",
            },
            "& .MuiInputBase-adornedStart svg": {
              fill: "#222",
            },

            "&.Mui-focused .MuiInputBase-adornedStart svg": {
              fill: "#00b2ff !important",
            },
          }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            type: "search",
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{ marginRight: "10px" }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            startAdornment: (
              <Box mr="12px">
                <SearchIcon size={24} />
              </Box>
            ),
          }}
        />
      )}
    />
  );
}
