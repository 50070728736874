import styled, { css } from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { Card as CardMUI } from "@mui/material";
import { radius } from "TGComponents/theme/radius";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  box-shadow: none;
  min-height: 289px;
`;

export const Title = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

export const ButtonMedia = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  border: 1px solid ${pallete.base.translucentBlack};
  color: ${pallete.base.blackAlpha};
  border-radius: 480px;
  padding: 0.5rem 1.25rem;
  text-align: center;
  font-size: 0.75rem;
`;

export const CardBody = styled.div`
  margin-top: 1rem;
  display: flex;
  max-height: 184px;
`;

export const BoxGraph = styled.div`
  width: 100%;
  max-height: 218px;

  canvas {
    height: 100% !important;
    max-width: 100% !important;
  }

  @media (min-width: 1200px) {
    canvas {
      height: 184px !important;
      max-height: 100%;
    }
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: auto;
`;

export const LegendItem = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  color: #222222;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  line-height: 11px;
  margin-top: 12px;
  & + & {
    margin-top: 30px;
  }

  @media (min-width: 1600px) {
    max-width: 400px;
    font-size: 0.875rem;
  }
`;

export const Labels = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelsItem = styled.span`
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: ${pallete.base.black};
  display: flex;
  align-items: center;

  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 0.5rem;
    ${(props) =>
      props.type === "Produto"
        ? css`
            background-color: #f5aa2a;
          `
        : css`
            background-color: #53a462;
          `}
  }

  & + & {
    margin-left: 1rem;
  }
`;
