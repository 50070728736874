import React from "react";
import { CardToggleInfo } from "TGComponents/global/cards";
import { Container } from "./styles";

const CardsPerformanceProducts = () => {
  return (
    <Container>
      <CardToggleInfo
        title="Receita"
        total={`R$ 0,00`}
        tooltipMessage="Receita total do evento selecionado"
        loading={false}
        footerText="--"
        type="up"
        value={24}
        colorValue="up"
      />

      <CardToggleInfo
        title="Ticket médio"
        total={`R$ 0,00`}
        tooltipMessage="Receita total do evento selecionado"
        loading={false}
        footerText="--"
        type="down"
        value={24}
        colorValue="down"
      />

      <CardToggleInfo
        title="Vendas por afiliados"
        total={`R$ 0,00`}
        tooltipMessage="Receita total do evento selecionado"
        loading={false}
        footerText="--"
        type="down"
        value={24}
        colorValue="down"
      />
    </Container>
  );
};

export default CardsPerformanceProducts;
