import styled, { css } from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { Tab as TabMUI } from "@mui/material";

export const Title = styled.h1`
  color: ${pallete.base.black};
  font-size: 1.5rem;
  line-height: 1.334;
  font-weight: 600;
  color: initial;
  margin: 0;

  @media (min-width: 1600px) {
    font-size: 2rem;
  }
`;

export const ButtonShowAndHideInfos = styled.button`
  border: 0;
  background: 0;

  @media (min-width: 1200px) {
    margin-right: 1rem;
  }
`;

export const Tab = styled(TabMUI)`
  font-size: 16px;
  color: ${pallete.base.blackAlpha};
  font-weight: 500;

  &.Mui-selected {
    font-weight: 600;
    color: ${pallete.base.black};
  }
`;

export const ButtonUpdatePage = styled.button`
  border: 0;
  background: 0;
  color: ${pallete.base.blackAlpha};
  font-size: 0.875rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-right: 16px;

  img {
    margin-right: 8px;
  }
`;

export const BoxCalendar = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 100;
`;

export const HideInfos = css`
  -webkit-text-security: disc;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-width: 2px;
  letter-spacing: 9px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
  width: 100%;
  overflow: hidden;
  display: flex;
`;
