import React from "react";
import { CardContent } from "@mui/material";

import { Card, Footer, Title } from "./styles";

export const CardWrapper = ({ title, children, footerText, onFooterClick }) => {
  return (
    <>
      <Card>
        <CardContent>
          <Title>{title}</Title>

          {children}
        </CardContent>

        {!!footerText && (
          <Footer>
            <button type="button" onClick={onFooterClick}>
              {footerText}
            </button>
          </Footer>
        )}
      </Card>
    </>
  );
};
