import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { externalTooltipHandlerBarChart } from "lib/tooltips/externalTooltipHandlerBarChart";
import { externalTooltipHandlerBarChartSecondary } from "lib/tooltips/externalTooltipHandlerBarChartSecondary";
import { deepMerge } from "lib/utils";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart = ({ options, data, variant, ...rest }) => {
  const defaultOptions = {
    cutout: 40,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external:
          variant === "secondary"
            ? externalTooltipHandlerBarChartSecondary
            : externalTooltipHandlerBarChart,
      },
    },
    parsing: {
      xAxisKey: "id",
      yAxisKey: "nested.value",
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    indexAxis: "y",
  };

  const finalOptions = deepMerge(defaultOptions, options);

  const pluginsOptions = [
    {
      id: "custom-empty-state-bar-chart",
      afterDatasetsDraw: (chart, args, plugins) => {
        const {
          ctx,
          data,
          chartArea: { top, left, width, height },
        } = chart;

        ctx.save();

        if (data.datasets.length === 0) {
          ctx.fillStyle = "#FFF";
          ctx.fillRect(left, top, width, height);

          ctx.textAlign = "center";
          ctx.font = "400 1rem Poppins, Inter, sans-serif";
          ctx.fillStyle = "#2222228F";

          ctx.fillText(
            "Dados insuficientes para gerar o relatório",
            left + width / 2,
            top + height / 2
          );
        }
      },
    },
  ];

  return (
    <Bar
      type="bar"
      options={finalOptions}
      data={data}
      {...rest}
      plugins={pluginsOptions}
    />
  );
};
