import { Box, CardContent } from "@mui/material";
import React from "react";
import { Card, ButtonMedia, BoxGraph, CardBody, Title } from "./styles";
import { LineChart } from "TGComponents/global/charts/LineChart";

const CardEvolutionSales = ({ options, data, mediaActive, onClickMedia }) => {
  const handleClickMedia = () => {
    !!onClickMedia && onClickMedia();
  };

  return (
    <>
      <Card>
        <CardContent>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Title>Evolução das vendas</Title>
            <ButtonMedia
              type="button"
              onClick={handleClickMedia}
              active={mediaActive}
            >
              Média
            </ButtonMedia>
          </Box>
          <CardBody>
            <BoxGraph>
              <LineChart {...{ options, data }} />
            </BoxGraph>
          </CardBody>
        </CardContent>
      </Card>
    </>
  );
};

export default CardEvolutionSales;
