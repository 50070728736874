import { Box, Typography } from "@mui/material";
import TGSideBarRight from "TGComponents/global/TGSideBarRight";
import { Link } from "react-router-dom";

const MenuMobile = ({ open, onClose, data }) => {
  const handleCloseDrawer = () => {
    onClose(false);
  };

  return (
    <Box display={["box", "box", "none"]}>
      <TGSideBarRight
        onClose={handleCloseDrawer}
        isOpen={open}
        title="Menu"
        rowGap={0}
      >
        <Box
          padding="20px 20px 40px 20px"
          justifyContent={"center"}
          alignItems={"start"}
          display={"grid"}
          gridTemplateColumns={"repeat(auto-fit, minmax(78px, 1fr))"}
          gap="10px"
          mb="auto"
        >
          {data.map((btn, index) => (
            <MenuCard key={index} btn={btn} />
          ))}
        </Box>
      </TGSideBarRight>
    </Box>
  );
};

function MenuCard({ btn }) {
  return (
    <Box
      component={Link}
      sx={{ textDecoration: "none" }}
      to={btn.to}
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      flexDirection={"column"}
      textAlign={"center"}
      maxWidth={"78px"}
      gap="14px"
    >
      <Box
        width={"78px"}
        height={"78px"}
        bgcolor="#fafafa"
        border="1px solid #eeeeee"
        borderRadius={"12px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <img src={btn.icon} height={"32px"} width={"32px"} alt={btn.title} />
      </Box>
      <Typography
        fontWeight={"500"}
        fontSize={"12px"}
        color="#555555"
        sx={{ wordBreak: "break-word" }}
      >
        {btn.title}
      </Typography>
    </Box>
  );
}

export default MenuMobile;
