import styled from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { Card as CardMUI } from "@mui/material";
import { radius } from "TGComponents/theme/radius";

export const Card = styled(CardMUI)`
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  box-shadow: none;
`;

export const Title = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

export const CardBody = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxGraph = styled.div`
  max-width: 120px;
  max-height: 120px;

  canvas {
    height: 100% !important;
    max-height: 100% !important;
  }
`;

export const BoxLegend = styled.div`
  margin-left: 1.125rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Total = styled.span`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${pallete.base.black};
  margin-bottom: 0.5rem;
`;

export const LegendLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 0.75rem;
    color: ${pallete.base.black};
  }

  span + span {
    color: ${pallete.base.blackAlpha};
    font-size: 0.625rem;
  }

  &:before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const LegendItem = styled.div`
  margin-top: 0.5rem;

  &:first-of-type {
    ${LegendLabel}:before {
      background-color: #53a462;
    }
  }

  &:nth-child(2) {
    ${LegendLabel}:before {
      background-color: #f28f0d;
    }
  }

  &:nth-child(3) {
    ${LegendLabel}:before {
      background-color: #d72b2b;
    }
  }

  &:nth-child(4) {
    ${LegendLabel}:before {
      background-color: #00a7f0;
    }
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const LegendTotal = styled.span`
  font-size: 0.75rem;
  color: ${pallete.base.black};
  margin-left: auto;
`;
