import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as notificationsActions from './../actions/notificationActions';

const PublicRoutes = () => {
  const navigate = useNavigate();

  const { type, message, show, redirectTo } = useSelector((state) => ({
    type: state.notification.type,
    message: state.notification.message,
    show: state.notification.show,
    redirectTo: state.notification.redirectTo,
  }));

  const dispatch = useDispatch();

  const notify = () => {
    const options = {
      position: 'top-right',
      theme: 'colored',
      onClose: () => dispatch(notificationsActions.clear()),
    };

    if (type === 'success') {
      toast.success(message, {
        ...options,
      });
    } else if (type === 'warning') {
      toast.warning(message, {
        ...options,
      });
    } else if (type === 'info') {
      toast.info(message, {
        ...options,
      });
    } else {
      toast.error(message, {
        ...options,
      });
    }
  };

  useEffect(() => {
    if (show) {
      if (redirectTo) {
        dispatch(notificationsActions.clearRedirect());
        navigate(redirectTo, { replace: true });
      } else {
        notify();
      }
    }
  }, [show, redirectTo]);

  return <Outlet />;
};

export default PublicRoutes;
