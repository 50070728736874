import { styled, css } from "@mui/material";
import pallete from "TGComponents/theme/pallete";
import { Link } from "react-router-dom";

export const Wrapper = styled("div")`
  margin-block: 2rem;
`;

export const Title = styled("div")`
  font-weight: 600;
  font-size: 1.5rem;
  color: ${pallete.base.black};
  margin-bottom: 0.75rem;
`;

export const LinkPage = styled(Link)`
  text-decoration: none !important;
  color: #00a7f0 !important;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1.8rem;
  display: inline-block;

  svg {
    margin-left: 1rem;
  }
`;

export const ScrollerWrapper = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  width: 100%;
  gap: 10px 20px;
`;

export const ScrollerHorizontal = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1.25rem;

  &::-webkit-scrollbar-track {
    background: #f7f9fa;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ebeef0;
  }
`;

export const CardsContainer = styled("div")`
  display: grid;
  align-items: center;
  gap: 20px;
  width: 100%;
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ReportsContainer = styled("div")`
  border-top: 1px solid #22222214;
  padding-top: 1rem;
  margin-top: 2rem;
  width: 100%;
`;

export const ReportsBox = styled("div")`
  ${({ theme }) => css`
    display: grid;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      grid-template-columns: 1fr auto;
      gap: 20px;
    }
  `}
`;

export const BoxGraph = styled("div")`
  display: flex;
  flex-direction: column;
`;
