import styled, { css } from "styled-components";
import pallete from "TGComponents/theme/pallete";
import { radius } from "TGComponents/theme/radius";
import { CircularProgress } from "@mui/material";

export const ProgressBar = styled(CircularProgress)`
  width: 30px !important;
  height: 30px !important;
`;

const wrapperModifierProgress = {
  up: () => css`
    background-color: #e5f3e7;

    &:before {
      border: 3px solid #e5f3e7;
    }

    ${ProgressBar} {
      color: #53a462;
    }
  `,

  down: () => css`
    background-color: #faf0f2;

    &:before {
      border: 3px solid #faf0f2;
    }

    ${ProgressBar} {
      color: #d72b2b;
    }
  `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${pallete.base.translucentBlack};
  border-radius: ${radius.lg};
  padding: 1.25rem;
`;

export const MetricCard = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 20px;
  }
`;

export const Progress = styled.div`
  ${({ percent }) => css`
    position: relative;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 0.75rem;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      background: #fff;
    }

    ${wrapperModifierProgress[percent >= 50 ? "up" : "down"]()}
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 0.75rem;
  color: ${pallete.base.blackAlpha};
  margin-bottom: 0.25rem;
`;

export const Value = styled.span`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${pallete.base.black};
  display: flex;
  align-items: center;
  ${(props) =>
    props.hideInfos &&
    css`
      -webkit-text-security: disc;
    `}
`;

export const Qauntity = styled.span`
  display: flex;
  align-items: center;

  ${(props) =>
    !props.hideInfos &&
    css`
      &::before {
        content: "";
        background-color: ${pallete.base.black};
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: inline-block;
        margin: 0 0.25rem;
      }
    `}
`;

export const Percent = styled.span`
  margin-left: auto;
  font-weight: 500;
  font-size: 1rem;
  color: ${pallete.base.black};
`;
