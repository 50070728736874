import React from "react";

import { Box, CardContent, Skeleton } from "@mui/material";
import { Card } from "./styles";

const CardGraphSkeleton = () => {
  return (
    <Card>
      <CardContent>
        <Skeleton
          variant="rounded"
          width="100%"
          height={20}
          sx={{ mb: "16px" }}
        />
        <Box
          display={"grid"}
          alignItems={"center"}
          gridTemplateColumns={"auto 1fr"}
          gap={"10px"}
        >
          <Skeleton variant="circular" width={120} height={120} />

          <Box display={"flex"} flexDirection={"column"} width={"100%"}>
            <Skeleton variant="rounded" width="100%" height={25} />

            <Skeleton
              variant="rounded"
              width="100%"
              height={25}
              sx={{ mt: "16px" }}
            />

            <Skeleton
              variant="rounded"
              width="100%"
              height={25}
              sx={{ mt: "16px" }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardGraphSkeleton;
