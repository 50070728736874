import { put, call } from "redux-saga/effects";
import { RequesterV2 } from "../services/requester";
import { store } from "store";
import * as dashboardActions from "../actions/dashboardActions";
import * as notificationActions from "../actions/notificationActions";

const _get = (endpoint, criteria) =>
  RequesterV2(`${endpoint}${criteria !== "" ? "?" + criteria : ""}`, {
    method: "GET",
  });

export function* getList(action) {
  const { dashboard } = store.getState();

  yield put(dashboardActions.setLoading(true));

  try {
    const { endpoint, criteria } = action.payload;

    if (criteria["tipo"] !== null && criteria["uuid_produto"] === null) {
      yield put(dashboardActions.setLoadingType("tab"));
    }

    if (criteria["tipo"] !== null && criteria["uuid_produto"]) {
      yield put(dashboardActions.setLoadingType("search"));
    }

    let buildCriteria = criteria
      ? Object.keys(criteria)
          .filter(
            (c) =>
              criteria[c] !== undefined &&
              criteria[c] !== null &&
              criteria[c] !== ""
          )
          .map((c) => `${c}=${encodeURIComponent(criteria[c])}`)
          .join("&")
      : false;

    const result = yield call(_get, endpoint, buildCriteria);

    yield put(dashboardActions.setResults(result.data));
    if (dashboard.loadingInitial) {
      yield put(dashboardActions.setLoadingInitial(false));
    }
  } catch (error) {
    console.log(error);
    yield put(
      notificationActions.error(
        "Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte"
      )
    );
  } finally {
    yield put(dashboardActions.setLoading(false));
  }
}

export function* getCardProductOrEvent(action) {
  yield put(dashboardActions.setLoadingCards(true));

  try {
    const { endpoint, criteria, type } = action.payload;

    let buildCriteria = criteria
      ? Object.keys(criteria)
          .filter(
            (c) =>
              criteria[c] !== undefined &&
              criteria[c] !== null &&
              criteria[c] !== ""
          )
          .map((c) => `${c}=${encodeURIComponent(criteria[c])}`)
          .join("&")
      : false;

    const result = yield call(_get, `${endpoint}/${type}/list`, buildCriteria);

    const selected =
      type === "product" ? result.data.produtos : result.data.eventos;

    yield put(dashboardActions.setResultsCards(selected));
    yield put(dashboardActions.setLoadingCards(false));
  } catch (error) {
    console.log(error);
    yield put(
      notificationActions.error(
        "Falha de comunicação. Verifique sua conexão com a internet ou entre em contato com o suporte"
      )
    );
  }
}
