import React from 'react';
import { Stack, Typography } from '@mui/material';
import bluearrow from 'assets/svgs/productadd/bluearrow.svg';
import { Link, useNavigate } from 'react-router-dom';

export default function TGBack({ href, rightComponent }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (!href) {
      e.preventDefault();
      navigate(-1);
    }
  };

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'space-between'}
      direction={'row'}
      gap={'12px'}
    >
      <Stack
        sx={{ textDecoration: 'none' }}
        component={href ? Link : 'a'}
        to={href ? href : '#'}
        onClick={handleClick}
        alignItems={'center'}
        direction={'row'}
        gap={'8px'}
      >
        <img src={bluearrow} alt='dragimg' height={'24px'} width={'24px'} />
        <Typography
          color='rgba(34, 34, 34, 0.56)'
          fontSize={['12px', '12px', '14px']}
          fontWeight={'500'}
        >
          Voltar
        </Typography>
      </Stack>
      {rightComponent ? rightComponent : null}
    </Stack>
  )
}
